@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-flow: column nowrap;

  gap: 40px;
  align-items: center;

  width: 100%;

  @media $landscapeTablet {
    gap: 80px;
  }
}

.title {
  color: #eeff85;
  font-weight: 900;
  font-size: 24px;

  font-family: $secondaryFont;
  line-height: 110%;

  font-variation-settings: 'wdth' 120;
}

.team {
  display: flex;
  flex-flow: column nowrap;

  gap: 24px;

  width: 100%;
  max-width: 834px;
  padding: 24px 16px;

  background-color: #eeff85;

  border-radius: 16px;

  @media $landscapeTablet {
    gap: 80px;
    padding: 80px;

    border-radius: 40px;
  }
}

.item {
  display: flex;
  flex-flow: column nowrap;

  gap: 12px;
  align-items: center;
  justify-content: center;

  padding: 24px 24px 16px;

  color: #0c0000;

  @media $landscapeTablet {
    padding: 0;
  }
}

.item + .item {
  border-top: 1px solid rgba(0, 0, 0, 0.16);

  @media $landscapeTablet {
    border-width: 0;
  }
}

.item p {
  margin: 0;

  font-weight: 400;

  font-size: 16px;
  line-height: 140%;
}

.item h3 {
  display: flex;
  flex-flow: column nowrap;

  gap: 4px;
  align-items: center;
  justify-content: center;

  order: -1;

  margin: 0;
  font-variation-settings: 'wdth' 72;

  @media $landscapeTablet {
    flex-flow: row nowrap;
    align-items: flex-start;
    order: initial;
  }
}

.item span {
  margin-top: 8px;

  color: #0c0000;
  font-weight: 900;
  font-size: 40px;

  font-family: $secondaryFont;
  line-height: 32px;

  text-transform: uppercase;

  font-variation-settings: 'wdth' 72;

  @media $landscapeTablet {
    font-size: 56px;
    line-height: 1;
  }
}

.item img {
  width: 54px;
  height: 72px;
}
