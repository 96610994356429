@import '../../styles/vars.css';

.root {
  display: block;
}

.root {
  margin: 0;

  font-weight: normal;
  font-size: 25px;

  font-family: $secondaryFont;
  line-height: 27px;

  @media $portraitTablet {
    font-size: 37px;
    line-height: 38px;
  }
}

.primary {
  font-weight: normal;
  font-family: $primaryFont;
}

.secondary {
  font-weight: 700;
  font-size: 24px;

  @media $portraitTablet {
    font-size: 36px;
  }
}
