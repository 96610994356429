/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  width: 100%;
  margin: 0;
  padding-right: 0;
  padding-bottom: 1px;
  padding-left: 0;

  font-family: $primaryFont;

  text-indent: 10px;

  background-color: transparent;

  border-width: 0;
  border-radius: 4px;
  outline-width: 0;
  box-shadow: inset 0 0 0 1px rgba(37, 0, 0, 1);

  appearance: none;
}

.root:focus {
  box-shadow: 0 0 0 1px rgba(37, 0, 0, 1), inset 0 0 0 1px rgba(37, 0, 0, 1);
}

.root[disabled] {

  color: #000;

  background: #f0f0f0;

  user-select: none;
}

.root::placeholder {
  color: rgba(37, 0, 0, 0.32);

  opacity: 1;
}

.invalid:not(:focus) {
  box-shadow: inset 0 0 0 1px #f95a5a;
}

.medium {

  height: 42px;

  font-size: 16px;
  line-height: 18px;
}
