/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  padding: 8px 4px 4px;

  background-color: #f9f8f5;

  @media $portraitTablet {
    padding: 8px 4px;
  }
}

.simple {
  @media $portraitTablet {
    padding-top: 60px;
  }
}

.container {
  width: 100%;
}

.cover {
  margin-bottom: 8px;

  @media $mobile {
    display: none;
  }
}

.head {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 16px;

  color: #250000;

  background-color: #f2f1ee;
  border-radius: 24px;

  @media $mobile {
    margin-bottom: 4px;
  }

  @media $portraitTablet {
    padding: 72px 20px 64px;
  }
}

.materialHeader {
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  width: 100%;
  max-width: 920px;

  @media $mobile {
    text-align: center;
  }
}

.article {
  position: relative;
}

.simple .container {
  max-width: 1060px;
}

.simple .article {
  margin-right: auto;
  margin-left: auto;

  @media $landscapeTablet {
    margin-right: 0;
    margin-left: 0;
  }
}

.default .article {
  width: 100%;
  max-width: 650px;
}

.body {
  padding: 0 16px;

  color: #250000;

  @media $landscapeTablet {
    padding: 0;
  }
}

.default .body {
  max-width: 650px;
  margin: 0 auto;

  @media $landscapeTablet {
    max-width: 1060px;
  }
}

.default .body {
  @media $landscapeTablet {
    display: flex;
    flex-flow: row nowrap;
  }
}

.hasMobileCover {
  padding-top: 0;

  @media $mobile {
    padding-top: 4px;
  }
}

.isSimple .head {
  margin-bottom: 28px;

  @media $mobile {
    margin-bottom: 64px;
  }
}
