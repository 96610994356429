@import '../../styles/vars.css';

.root {
  position: relative;
  z-index: 10;

  display: block;
  overflow: hidden;

  text-decoration: none;

  border-radius: 8px;
  outline-width: 0;
}

.root:hover {
  @media $mobile {
    opacity: 0.95;
  }
}

.root:last-child {
  margin-bottom: 0;
}

.root:first-child {
  margin-top: 0;
}

.root::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;

  border-radius: 8px;

  content: '';
}

.root::before {
  display: block;

  width: 100%;
}

.root::after {
  box-shadow: inset 0 4px #b88b59, inset 0 5px rgba(0, 0, 0, 0.2),
    inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.root,
.wrapper {
  margin: 16px 0;

  @media $portraitTablet {
    margin: 25px 0;
  }
}

.overlay,
.overlayHeader {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 10;
}

.overlay {
  bottom: 0;

  height: 295px;
}

.overlayHeader {
  top: 0;

  height: 150px;
}

.body {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  padding: 12px 15px;

  background-color: #f7f7f7;

  @media $mobile {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    min-height: 295px;
    padding: 20px;

    background-color: transparent;
  }
}

.isRich {
  color: #000;

  @media $mobile {
    color: #fff;
  }
}

.isRich.hasGradient .body {
  @media $mobile {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    min-height: 295px;

    background-color: transparent;
  }
}

.isRich .picture {
  position: relative;
}

.isRich .picture::after {
  position: absolute;
  right: 1px;
  bottom: 0;
  left: 1px;
  z-index: 10;

  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  content: '';

  @media $mobile {
    content: '';
  }
}

.isCard {
  color: #fff;

  background-color: #191919;

  @media $mobile {
    min-height: 350px;
  }
}

.isCard .body {
  background-color: transparent;

  @media $mobile {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    min-height: 295px;
  }
}

.isCard::before {
  @media $mobile {
    padding-bottom: 66.66%;

    content: '';
  }
}

.isCard .cover {
  position: relative;

  width: 100%;

  @media $mobile {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

.isCard .cover::before {
  display: block;

  padding-bottom: 78.50746269%;

  content: '';

  @media $mobile {
    content: none;
  }
}

.isCard .picture {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 180px;
  height: 180px;

  transform: translate(-50%, -50%);

  @media $mobile {
    top: 25%;

    transform: translate(-50%, -25%);
  }
}

.tag {
  position: absolute;
  top: 14px;
  right: 15px;
  left: 15px;
  z-index: 10;

  color: rgba(255, 255, 255, 0.7);

  font-size: 12px;

  @media $landscapeTablet {
    top: 16px;
    right: 20px;
    left: 20px;
  }
}

.center {
  width: 100%;
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;
}

/* Themes */

.isRich.dark {
  color: #000;
}

.isRich.dark .tag {
  color: rgba(0, 0, 0, 0.7);
}

.isRich.light {
  @media $mobile {
    color: #fff;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  }
}

/* Display */

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
