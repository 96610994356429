@import '../../styles/vars.css';

.root {
  position: relative;

  background: #fcfcfa;

  border: 2px solid rgba(225, 55, 130, 0.24);
  border-radius: 24px;
  box-shadow: 0 11px 24px 0 rgba(51, 0, 0, 0.04),
    0 44px 44px 0 rgba(51, 0, 0, 0.03),
    0 99px 59px 0 rgba(51, 0, 0, 0.02),
    0 176px 70px 0 rgba(51, 0, 0, 0.01),
    0 275px 77px 0 rgba(51, 0, 0, 0);
}

.body {

  max-height: 400px;
  padding: 24px;
  overflow-y: auto;

  color: #250000;
  font-weight: 400;
  font-size: 20px;

  font-family: $primaryFont;
  line-height: 28px;
  -webkit-overflow-scrolling: touch;

  overscroll-behavior: none;
}

.body::before,
.body::after {
  position: absolute;
  right: 0;
  left: 0;

  content: '';
  pointer-events: none;
}

.body::before {
  top: 0;

  height: 29px;

  background: linear-gradient(0deg, rgba(255, 255, 250, 0) 0%, #fcfcfa 100%);

  border-radius: 24px 24px 0 0;
}

.body::after {
  bottom: 0;

  height: 48px;

  background: linear-gradient(180deg, rgba(255, 255, 250, 0) 0%, #fcfcfa 100%);

  border-radius: 0 0 24px 24px;
}

.body h3 {
  margin: 0 0 4px;

  color: #e13782;
  font-weight: 700;

  font-size: 20px;
  font-style: normal;
  line-height: 140%;
}

.body p {
  margin: 0;
}

.body p + p {
  margin-top: 6px;
}

.body a {
  color: inherit;

  box-shadow: inset 0 -1px #e13782;
}

.body a:hover {
  color: #e13782;
}

.dismiss {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;

  margin: 0;
  padding: 18px 18px 12px 12px;

  color: rgba(37, 0, 0, 1);

  background-color: transparent;
  border-width: 0;
  outline: none;

  cursor: pointer;

  fill: transparent;
}

.dismiss:hover {
  fill: rgba(37, 0, 0, 1);
}

.dismiss svg {
  width: 13px;
  height: 13px;
}
