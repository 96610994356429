/* stylelint-disable declaration-empty-line-before */
@import '../../../../../styles/vars.css';

.root {
  position: relative;
  z-index: $zIndex-2;
}

.root:empty {
  margin-top: 0;
}

.title {
  margin: 0;
  padding: 32px 8px;

  color: #ccc;
  font-weight: 400;
  font-size: 14px;

  font-family: $primaryFont;
  line-height: 20px;

  text-align: center;
  text-transform: uppercase;

  @media $mobile {
    padding: 48px 8px;
  }
}
