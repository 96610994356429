/* stylelint-disable selector-no-qualifying-type */

span[data-body] {
  position: relative;

  padding: 6px 8px 4px;

  color: #e13782;
  white-space: pre-wrap;

  background: rgba(225, 55, 130, 0.02);
  border-radius: 18px;
  box-shadow: inset 1px 0 rgba(225, 55, 130, 0.16),
    inset 0 1px rgba(225, 55, 130, 0.16), inset -1px 0 rgba(225, 55, 130, 0.16),
    inset 0 -1px rgba(225, 55, 130, 0.16);

  cursor: pointer;
}

span[data-body]:hover {
  background: rgba(225, 55, 130, 0.06);
}

span[data-highlight='true'] {
  background: rgba(225, 55, 130, 0.02);
  box-shadow: inset 1px 0 rgba(225, 55, 130, 0.8),
    inset 0 1px rgba(225, 55, 130, 0.8), inset -1px 0 rgba(225, 55, 130, 0.8),
    inset 0 -1px rgba(225, 55, 130, 0.8);
}
