/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  position: relative;
  z-index: $zIndex-2;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 20px 20px;

  color: #999;

  font-family: $primaryFont;

  text-align: center;
}

.nav a {

  color: #ccc;

  transition: color 150ms ease-out;
}

.nav a:hover {
  color: rgba(255, 255, 255, 0.5);
}

.container {
  width: 100%;
  max-width: $layoutWidth;
}

.tip {
  display: none;
}

.groupList {
  margin: 0;
  padding: 0;

  font-weight: 400;

  font-size: 16px;
  line-height: 20px;

  list-style: none;
}

.expanded .platforms li:last-child {
  width: 100%;
}

.groupList a {
  display: block;

  padding: 12px 0;
}

.nav {
  display: flex;
  flex-flow: column wrap;

  margin: 0 auto 20px;
}

.group {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  width: 100%;

  @media $landscapeTablet {
    display: block;
  }
}

.panel {
  color: #ccc;
}

.main {
  position: relative;

  display: flex;
  flex-flow: column nowrap;
}

.hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  overflow: hidden;

  clip: rect(0 0 0 0);
}

[data-route='membership'] .container {
  max-width: none;
}

[data-route='membership'] .groupList a {
  color: #0c0000;
}

[data-route='membership'] .groupList a:hover {
  color: rgba(12, 0, 0, 0.48);
}
