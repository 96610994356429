@import 'vars.css';

html {
  font-size: 16px;

  @media $landscapeTablet {
    font-size: calc(100vw / 66.75);
  }

  @media (min-width: $layoutWidth) {
    font-size: 20px;
  }
}

body {
  font-family: $primaryFont;

  @media (min-width: 83.4375em) {
    background-color: #000;
  }
}

[data-lightbox='true'] {
  overflow: hidden;
}
