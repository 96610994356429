@import '../../styles/vars.css';

.root {
  margin: 0 0 18px;

  font-weight: normal;
  font-size: 20px;

  font-family: $primaryFont;
  line-height: 23px;

  @media $portraitTablet {
    margin-bottom: 30px;

    font-size: 36px;
    line-height: 40px;
  }
}

/* VISIBILITY */

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
