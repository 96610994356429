/* stylelint-disable declaration-empty-line-before */

@import '../../../../styles/vars.css';

.root {
  color: inherit;

  font-size: 20px;
  line-height: 23px;

  @media $landscapeTablet {
    font-size: calc(19rem / $fontSize);
    line-height: 105%;
  }
}

.first {
  font-weight: 900;
  font-size: inherit;
  font-family: $secondaryFont;
}

.second {
  margin: 4px 0 0;

  font-weight: 400;
  font-size: 16px;

  font-family: $primaryFont;
  line-height: 20px;
}

.is1to1 {
  font-size: 48px;
}

.is1to2,
.is1to3,
.is1to4 {
  @media $landscapeTablet {
    font-size: 24px;
  }
}

.isMobile.isInRichImage .first {
  font-size: 20px;
  line-height: 115%;
  font-variation-settings: 'wdth' 80, 'slnt' 0;
}

.is1to1 .second {
  font-size: 24px;
  line-height: 32px;
}

.is1to1 .first {
  font-variation-settings: 'wdth' 130, 'slnt' 0;
}

.is1to2 .first {
  font-variation-settings: 'wdth' 100, 'slnt' 0;
}

.is1to3 .first {
  font-variation-settings: 'wdth' 100, 'slnt' 0;
}

.is1to4 .first {
  font-variation-settings: 'wdth' 80, 'slnt' 0;
}

.isFeatured .first {
  font-size: 48px;
  line-height: 95%;

  font-variation-settings: 'wdth' 105, 'slnt' 0;
}

.isFeatured .second {
  @media $landscapeTablet {
    font-size: 24px;
    line-height: 32px;
  }
}

.is1to1.isInRichImage .second,
.is1to1.isInRichText .second,
.is1to2.isInRichText .second {
  @media $landscapeTablet {
    font-size: 24px;
    line-height: 32px;
  }
}

.is1to1.isInRichText .first,
.is1to2.isInRichText .first {
  @media $landscapeTablet {
    font-weight: 900;
    font-size: 48px;
    line-height: 100%;
  }
}

.is1to1.isInRichText .second,
.is1to2.isInRichText .second {
  @media $landscapeTablet {
    max-width: 685px;

    font-size: 24px;
    line-height: 32px;
  }
}

.is1to4.isInRichText .first {
  @media $landscapeTablet {
    font-variation-settings: 'wdth' 100, 'slnt' 0;
  }
}

.is1to1.isInRichText .second {
  @media $landscapeTablet {
    max-width: 654px;
  }
}

.is1to1.isInRichText,
.is1to2.isInRichText {
  @media $landscapeTablet {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
