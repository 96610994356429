@import '../../styles/vars.css';

.root {
  position: relative;

  display: block;

  width: 100%;
}

.isVisible .marker {
  animation: show 400ms ease both 500ms;
}

.dot {
  position: absolute;
  z-index: 10;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;

  will-change: opacity;
}

.marker {
  width: 20px;
  height: 20px;
  padding: 0;

  color: #fff;
  font-weight: 600;
  font-size: 9px;

  font-family: $secondaryFont;
  line-height: 22px;
  text-align: center;

  background-color: #000;
  border-width: 0;
  border-radius: 30px;
  outline-width: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  cursor: pointer;

  opacity: 0;

  appearance: none;
  will-change: opacity;
  -webkit-tap-highlight-color: transparent;

  @media $mobile {
    width: 28px;
    height: 28px;

    font-size: 13px;
    line-height: 28px;
  }
}

.marker::after {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;

  content: '';

  @media $mobile {
    content: none;
  }
}

.marker svg {
  display: block;

  width: 100%;
  height: 100%;

  will-change: opacity;
}

.marker:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

.marker.isActive {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0 0 0 1px #fff;
}

.popover {
  position: absolute;
  z-index: 10;
}

.popover.isRight.isTop {
  transform: translateX(50px);
}

.popover.isLeft.isTop {
  transform: translateX(-100%) translateX(-22px);
}

.popover.isRight.isBottom {
  transform: translateX(50px) translateY(-100%) translateY(22px);
}

.popover.isLeft.isBottom {
  transform: translateX(-100%) translateX(-22px) translateY(-100%)
    translateY(22px);
}

@keyframes show {
  0% {
    transform: scale(0.4);

    opacity: 0;
  }

  90% {
    transform: scale(1.1);

    opacity: 1;
  }

  100% {
    transform: scale(1);

    opacity: 1;
  }
}
