@import '../../styles/vars.css';

.root {
  margin: calc(15rem / 16) 0;
  padding: 0;

  font-size: calc(18rem / 16);

  font-family: $primaryFont;
  line-height: calc(24rem / 16);

  list-style: none;

  @media $mobile {
    font-size: 20px;
    line-height: 28px;
  }

  @media $portraitTablet {
    margin: 20px 0;
  }
}

.root:first-child {
  margin-top: 0;
}

.root:last-child {
  margin-bottom: 0;
}

.root a {
  color: inherit;

  box-shadow: inset 0 -1px $brandColor;
}

.root a:hover {
  color: $brandColor;
}

.root li {
  position: relative;

  margin-bottom: 6px;
}

.root mark {
  position: relative;

  font-weight: inherit;
  font-style: inherit;

  white-space: pre-wrap;

  background-color: $highlighter;

  @media $mobile {
    padding-bottom: 1px;
  }
}

.root a mark {
  color: inherit;

  box-shadow: inset 0 -1px $brandColor;
}

.ol {
  counter-reset: list 0;
}

.ol li {
  padding: 0 0 0 33px;
}

.ol li::before {
  position: absolute;
  left: 0;

  width: 23px;

  color: #ff3b0e;
  white-space: nowrap;
  text-align: right;

  content: counter(list) '. ';
  counter-increment: list;
}

.ul li {
  padding: 0 0 0 23px;

  @media $portraitTablet {
    padding: 0 0 0 38px;
  }
}

.ul li:last-child {
  margin-bottom: 0;
}

.ul li::before {
  position: absolute;
  top: 0;
  left: 5px;

  width: 8px;

  color: #eb2b00;

  font-size: 18px;
  line-height: inherit;
  text-align: center;

  content: '\2022';

  @media $portraitTablet {
    left: 0;

    width: 36px;

    font-size: 20px;

    text-align: center;
  }
}

.center {
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;

  @media $portraitTablet {
    max-width: 970px;
    padding-left: 320px;
  }
}

.isInImportantLead {
  font-size: 1rem;
  font-family: $secondaryFont;
  line-height: calc(20rem / 16);

  @media $portraitTablet {
    font-size: 17px;
    line-height: 24px;
  }
}

.dark a {
  color: inherit;
}

.dark a:hover {
  color: $brandColor;
}

/* VISIBILITY */

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
