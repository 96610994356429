@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-flow: column nowrap;

  gap: 80px;
  align-items: center;

  width: 100%;
}

.title {
  color: #eeff85;
  font-weight: 900;
  font-size: 24px;

  font-family: $secondaryFont;
  line-height: 110%;

  font-variation-settings: 'wdth' 120;
}

.items {
  max-width: 650px;

  border-bottom: 1px solid #eeff85;
}

.item {
  padding: 16px 0 8px;

  color: #eeff85;

  border-top: 1px solid #eeff85;
}

.control {
  position: relative;

  width: 100%;
  margin: 0;
  padding: 0 28px 0 0;

  text-align: left;

  background-color: transparent;

  border-width: 0;

  cursor: pointer;
}

.itemTitle {
  display: block;

  color: #eeff85;
  font-weight: 900;
  font-size: 40px;

  font-family: $secondaryFont;
  line-height: 1;

  text-transform: uppercase;

  font-variation-settings: 'wdth' 72;

  @media $landscapeTablet {
    font-size: 56px;
  }
}

.control::after {
  position: absolute;
  top: 50%;
  right: 0;

  width: 21px;
  height: 20px;

  background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.683 8.743c-.723 1.02-1.453 2.057-2.123 3.135-.703-.966-1.285-1.957-1.875-2.962l-.178-.303c-.75-1.277-1.532-2.568-2.59-3.772-.255-.62-.867-.932-1.429-.94-.61-.01-1.267.34-1.44 1.116-.16.664.105 1.186.393 1.574a7.082 7.082 0 0 0 .435.52c.141.16.252.292.334.42l.01.016.012.016c.791 1.044 1.496 2.22 2.224 3.434l.263.439c.808 1.342 1.655 2.71 2.662 3.906.19.329.45.57.772.685.336.12.675.081.965-.035.546-.217 1.002-.731 1.222-1.294.845-1.425 1.763-2.736 2.692-4.058l.066-.094c.913-1.3 1.836-2.612 2.69-4.035l.006-.011.006-.012c.234-.442.254-.894.106-1.287a1.597 1.597 0 0 0-.76-.846 1.483 1.483 0 0 0-1.127-.115c-.393.124-.708.423-.885.864-.722 1.195-1.537 2.346-2.365 3.516l-.086.123Z' stroke='%23EEFF85' stroke-linecap='round'/%3E%3C/svg%3E");
  transform: translateY(-50%);

  content: '';
}

.control[aria-expanded='true']::after {
  transform: translateY(-50%) rotate(180deg);
}

.control:hover::after {
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.683 8.743c-.723 1.02-1.453 2.057-2.123 3.135-.703-.966-1.285-1.957-1.875-2.962l-.178-.303c-.75-1.277-1.532-2.568-2.59-3.772-.255-.62-.867-.932-1.429-.94-.61-.01-1.267.34-1.44 1.116-.16.664.105 1.186.393 1.574a7.082 7.082 0 0 0 .435.52c.141.16.252.292.334.42l.01.016.012.016c.791 1.044 1.496 2.22 2.224 3.434l.263.439c.808 1.342 1.655 2.71 2.662 3.906.19.329.45.57.772.685.336.12.675.081.965-.035.546-.217 1.002-.731 1.222-1.294.845-1.425 1.763-2.736 2.692-4.058l.066-.094c.913-1.3 1.836-2.612 2.69-4.035l.006-.011.006-.012c.234-.442.254-.894.106-1.287a1.597 1.597 0 0 0-.76-.846 1.483 1.483 0 0 0-1.127-.115c-.393.124-.708.423-.885.864-.722 1.195-1.537 2.346-2.365 3.516l-.086.123Z' stroke='%23f2ffa2' fill='%23f2ffa2' stroke-linecap='round'/%3E%3C/svg%3E");
}

.control:hover .itemTitle {
  color: #f2ffa2;
}

.body {
  padding-top: 8px;
  padding-bottom: 16px;

  font-weight: 400;
  font-size: 16px;

  font-family: $primaryFont;
  line-height: 140%;

  @media $landscapeTablet {
    font-size: 20px;
  }
}

.body a {

  color: inherit;
  text-decoration: underline;

  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}
