@import '../../styles/vars.css';

.root {
  margin-bottom: 11px;

  @media $landscapeTablet {
    margin-bottom: 10px;

    text-align: center;
  }
}

.index {
  margin-bottom: 7px;

  color: $brandColor;
  font-weight: 700;
  font-size: 20px;

  font-family: $secondaryFont;
  line-height: 24px;
}

.title {
  color: #1d1d1d;
  font-weight: 700;
  font-size: 20px;

  font-family: $secondaryFont;
  line-height: 24px;

  @media $landscapeTablet {
    font-size: 28px;
    line-height: 34px;
  }
}

/* VISIBILITY */

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
