@import '../../styles/vars.css';

.root {
  position: relative;

  text-align: left;
}

.main {
  display: flex;
  flex-flow: column nowrap;

  @media $landscapeTablet {
    flex-flow: row nowrap;
    align-items: center;
  }
}

.body {
  order: -1;

  max-width: 860px;
  margin: 0 auto;

  @media $portraitTablet {
    text-align: center;
  }

  @media $landscapeTablet {
    align-items: flex-start;
    justify-content: center;
    order: 0;

    width: 50%;
    max-width: none;
    height: 100%;
    margin-left: 25px;

    text-align: left;
  }
}

.footer {
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  padding-top: 3px;

  @media $landscapeTablet {
    max-width: 1000px;
    margin: auto;
    padding-top: 0;

    border-top: 1px solid #e6e6e6;
  }
}

.image {
  flex-grow: 1;

  margin: 0 -20px;
  margin-top: 18px;

  @media $mobile {
    margin: 31px 0 -5px;
  }

  @media $landscapeTablet {
    width: 50%;
    margin: 0;
  }
}

/* VISIBILITY */

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
