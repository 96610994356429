@import '../../styles/vars.css';

@import './FootnoteLink';

.p {
  margin: 0 0 16px;

  font-size: 16px;

  font-family: $primaryFont;
  line-height: 22px;

  @media $mobile {
    margin: 0 0 20px;

    font-size: 20px;
    line-height: 28px;
  }
}

.p strong {
  font-weight: 400;

  font-variation-settings: 'wght' 700, 'slnt' 0;
}

.p a,
.h2 a,
.h3 a,
.h4 a,
.lead a,
.lead_hr a,
.context_p a {

  color: inherit;
  text-decoration: underline;

  text-decoration-color: #ff3b0e;
  text-decoration-skip-ink: none;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}

.p a:hover,
.h2 a:hover,
.h3 a:hover,
.h4 a:hover,
.lead a:hover,
.lead_hr a:hover,
.context_p a:hover {
  color: #ff3b0e;
}

.h3:first-child,
.h4:first-child,
.p:first-child,
.lead:first-child,
.blockquote:first-child {
  margin-top: 0;
}

.p:last-child,
.blockquote:last-child,
.lead:last-child,
.h2:last-child {
  margin-bottom: 0;
}

.h2 {
  max-width: 520px;
  margin: 0 0 36px;

  font-weight: 900;
  font-size: 48px;

  font-family: $secondaryFont;
  line-height: 43px;

  text-align: center;
  text-transform: uppercase;

  font-variation-settings: 'wdth' 62, 'slnt' 0;

  @media $mobile {
    margin: 0 0 45px;

    font-size: 72px;
    line-height: 90%;
  }
}

.h2 + figure {
  margin-top: calc(15rem / 16);

  @media $mobile {
    margin-top: -20px;
  }
}

.h3 {
  margin-top: 32px;
  margin-bottom: 16px;

  font-weight: 900;
  font-size: 24px;

  font-family: $secondaryFont;
  line-height: 100%;

  text-transform: uppercase;

  font-variation-settings: 'wdth' 100, 'slnt' 0;

  @media $mobile {
    margin-top: 40px;
    margin-bottom: 16px;

    font-size: 32px;
  }
}

.h4 {
  margin-top: 32px;
  margin-bottom: 16px;

  font-weight: 900;
  font-size: 20px;

  font-family: $secondaryFont;
  line-height: 110%;

  font-variation-settings: 'wdth' 100, 'slnt' 0;

  @media $mobile {
    margin-top: 48px;
    margin-bottom: 8px;
  }
}

.h3 + .h3,
.h2 + .h2 {
  margin-top: 0;
}

.h2 + .h4,
.h3 + .h4 {
  margin-top: 24px;

  @media $mobile {
    margin-top: 15px;
  }
}

.p + ul,
.p + ol {
  margin-top: calc(-9rem / 16);
  @media $mobile {
    margin-top: -9px;
  }
}

.blockquote blockquote {
  margin: 24px 0;
  padding: 20px;

  color: #eb2b00;
  font-weight: normal;
  font-size: 16px;

  font-family: $primaryFont;
  line-height: 140%;

  background: #f7f1ed;
  border-radius: 16px;

  @media $mobile {
    margin: 25px 0;
    padding: 20px;

    font-size: 20px;
    line-height: 28px;
  }
}

.blockquote a {

  color: inherit;
  text-decoration: underline;

  text-decoration-skip-ink: none;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}

.blockquote a:hover {
  text-decoration: none;
}

.lead {
  position: relative;

  margin-top: 28px;
  margin-bottom: 16px;

  font-size: 16px;

  font-family: $primaryFont;
  line-height: 22px;

  font-variation-settings: 'wght' 500, 'slnt' 0;

  @media $mobile {
    margin-top: 32px;

    font-size: 24px;
    line-height: 32px;

    font-variation-settings: 'wght' 450, 'slnt' 0;
  }
}

.lead + .lead {
  margin-top: 0;
}

.lead + .p,
.lead + .h3,
.lead + .h4,
.lead + .blockquote,
.lead + .context_p {
  margin-top: 48px;

  @media $mobile {
    margin-top: 56px;
  }
}

.lead [data-body] {
  padding-top: 1px;
}

.lead_hr {
  display: none;
}

.divider {
  width: 100%;
  margin: 35px auto;

  @media $mobile {
    margin-top: 45px;
    margin-bottom: 45px;
  }
}

.divider hr {
  display: block;

  max-width: 650px;
  height: 2px;

  background-color: #ff3b0e;

  border-width: 0;

  content: '';
}

mark {
  position: relative;

  font-weight: inherit;
  font-style: inherit;

  white-space: pre-wrap;

  background: rgba(238, 255, 133, 1);

  @media $mobile {
    padding-top: 2px;
    padding-bottom: 1px;
  }
}

a mark {
  z-index: 1;

  padding-bottom: 0;

  color: inherit;
}

.context_p {
  position: relative;

  margin: 0 0 calc(15rem / 16);
  padding: 0 0 0 calc(23rem / 16);

  font-size: calc(18rem / 16);

  font-family: $primaryFont;
  line-height: calc(22rem / 16);

  @media $portraitTablet {
    margin: 0 0 15px;
    padding: 0 0 0 38px;

    font-size: 20px;
    line-height: 27px;
  }
}

.context_p::before {
  position: absolute;
  top: 0;
  left: 5px;

  width: 8px;

  color: $brandColor;

  font-size: calc(18rem / 16);
  line-height: inherit;
  text-align: center;

  content: '\2022';

  @media $portraitTablet {
    left: 0;

    width: 36px;

    font-size: 20px;

    text-align: center;
  }
}

.isInIntro.lead::after {
  content: none;
}

.center {
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;

  @media $landscapeTablet {
    max-width: 970px;
    padding-left: 320px;
  }
}

.center.lead {
  @media $portraitTablet {
    font-size: 20px;
    line-height: 28px;
  }
}

.isInImportantLead {
  font-size: 1rem;
  font-family: $secondaryFont;
  line-height: calc(22rem / 16);

  @media $portraitTablet {
    font-size: 18px;
    line-height: 26px;
  }
}

/* VISIBILITY */

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
