@import '../../styles/vars.css';

.root {
  position: relative;

  width: 100%;

  max-width: 650px;
  margin: 48px auto 8px;

  text-align: center;

  @media $landscapeTablet {
    max-width: 970px;
    margin-top: 48px;
    padding-left: 320px;
  }
}

.root:first-child {
  margin-top: 0;
}

.root:last-child {
  margin-bottom: 0;
}

.subtitle {
  display: inline-block;

  font-weight: 400;
  font-size: 12px;

  font-family: $primaryFont;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;

  vertical-align: top;

  @media $mobile {
    font-size: 14px;
    line-height: 20px;
  }
}

/* VISIBILITY */

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
