/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  width: 100%;
}

.root iframe {
  width: 100%;

  border-width: 0;
}

.root pgs-ad {
  width: 100%;
}

.header {
  width: 100%;
  margin-bottom: 6px;

  color: #808080;
  font-weight: normal;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 15px;

  text-align: center;

  @media $landscapeTablet {
    line-height: 21px;
  }
}

.AfterMaterial {
  width: auto;
  margin: 0 auto;

  text-align: center;

  @media $landscapeTablet {
    width: 100%;
    max-width: $layoutWidth;
    margin-right: auto;
    margin-left: auto;
  }
}

.within {
  width: auto;
  max-width: 540px;
  margin: 20px -20px;
  overflow: hidden;

  text-align: center;

  background-color: #f2f2f2;

  @media $mobile {
    margin-right: auto;
    margin-left: auto;
  }

  @media $portraitTablet {
    max-width: none;
    margin: 25px -4px;
  }
}

.top {
  padding: 0;
}

.middle .body {
  height: 50px;
}

.container {
  width: 100%;

  vertical-align: top;

  @media $mobile {
    width: auto;

    border-radius: 1px;
  }
}

.middle .container {
  min-width: 300px;
  padding: 6px 0 10px;

  @media $landscapeTablet {
    min-width: 970px;
  }
}

.bottom .container {
  min-width: 300px;

  @media $landscapeTablet {
    min-width: 970px;
  }
}

.within .container {
  min-width: 300px;
  padding: 26px 0 5px;

  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @media $portraitTablet {
    width: 100%;
    padding-bottom: 3px;
  }
}

.top .container {
  @media $landscapeTablet {
    min-width: 970px;

    border-bottom: 1px solid #d9d9d9;
  }
}

.body {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  animation: fade 500ms linear;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.content {
  width: 100%;
  max-width: none;
  overflow: hidden;

  line-height: 0;
}

.top .content {
  max-width: 100%;
}

.top .content > div {
  min-width: 100%;

  text-align: center;
}

.top .container {
  @media $landscapeTablet {
    padding: 0;

    border-width: 0;
  }
}

.top .header {
  display: none;
}

.within iframe {
  @media $landscapeTablet {
    width: 100%;
  }
}

.within .content > div {
  @media $landscapeTablet {
    min-width: 100%;

    text-align: center;
  }
}

.bottom .body {
  min-height: 250px;
}

.within .body {
  order: 1;

  min-height: 250px;
  margin-bottom: 5px;

  @media $landscapeTablet {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1px;
    margin-left: 0;
  }
}

.bottom .content,
.within .content {
  max-width: none;
}

.side {
  position: sticky;
  top: 75px;

  padding: 7px 9px 10px;

  border: 1px solid #ebebeb;
  border-radius: 8px;
}

.side .container {
  width: 100%;
}

.side .content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.footer {
  padding-top: 5px;

  text-align: center;
}

.link {
  color: #808080;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 21px;
  text-decoration: underline;
}

.link:hover {
  color: $brandColor;
}

.link::before {
  display: inline-block;

  padding: 0 3px;

  content: '\00b7';
}

.link:first-child::before {
  display: none;
}

.isInApp .content {
  max-width: 350px;

  @media $landscapeTablet {
    max-width: none;
  }
}

.top .body iframe {
  @media $mobileMax {
    width: 100px;
    min-width: 100%;
  }
}

.isInFeed {
  width: 100%;
  max-width: 300px;
  margin: 30px auto 12px;
  overflow: hidden;
}

.isInFeed .body {
  height: 250px;

  background-color: #e9e9e9;
}

.bottom .container,
.within .container,
.card .container,
.isInFeed .container {
  display: inline-flex;
  flex-flow: column nowrap;
}

.bottom .header,
.within .header,
.isInFeed .header {
  display: block;
  order: 2;

  margin: 0;
}

.bottom .body {
  width: 100%;

  background-color: rgba(255, 255, 255, 0.07);
}

.bottom .body,
.isInFeed .body {
  order: 1;

  margin-bottom: 5px;
}

.isInFeed .content {
  max-width: 300px;
}

.card {
  width: auto;
  max-width: 540px;
  margin: 30px auto 12px;
  overflow: hidden;

  text-align: center;

  @media $landscapeTablet {
    margin-top: 25px;
    margin-bottom: 3px;
  }
}

.card .body {
  order: -1;

  width: 100%;
  max-width: 320px;
  min-height: 250px;
  margin: 0 auto 5px;

  @media $landscapeTablet {
    max-width: 336px;
  }
}

.card .header {
  margin: 0;
}

/* UNDER THE SUN */

.under_the_sun {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;

  @media $desktop {
    max-width: 970px;
  }
}

.under_the_sun .container {
  display: inline-flex;
  flex-flow: column nowrap;

  width: 100%;
}

.under_the_sun .body {
  order: -1;
}

.after_material .content,
.main_in_content .content {
  min-height: 250px;

  line-height: 0;

  @media $desktop {
    min-height: 91px;
  }
}

.under_the_sun .content {
  display: inline-flex;

  width: auto;

  background-color: rgba(255, 255, 255, 0.07);

  @media $desktop {
    min-width: 728px;
  }
}

.under_the_sun .header {
  margin-bottom: 0;
  padding-top: 6px;
  padding-bottom: 0;

  @media $desktop {
    padding-top: 4px;
  }
}

/* New DFP Banners */

.main_in_content {
  height: 250px;
}
