@import '../../styles/vars.css';

.root {
  position: relative;
}

.body {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;

  min-height: 412px;
  padding: 0 20px 12px;
}

.image {
  position: relative;
}

.rich {
  margin: 0 -20px;
  overflow: hidden;

  border-radius: 9px 9px 0 0;
}

.rich::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px 10px 0 0;

  content: '';
}

.dark .body {
  color: #000;
}

.light .body {
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.control {
  position: absolute;
  right: 49px;
  bottom: 12px;
  z-index: 10;

  width: 28px;
  height: 28px;
  margin: 0;
  padding: 0;

  color: #fff;

  background-color: rgba(0, 0, 0, 0.7);
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E %3Cg fill='%23FEFEFE' fill-rule='evenodd' opacity='.7'%3E %3Cpath d='M12.726 16H3.275A3.276 3.276 0 0 1 0 12.725v-9.45A3.276 3.276 0 0 1 3.275 0h9.45A3.275 3.275 0 0 1 16 3.275v9.45A3.275 3.275 0 0 1 12.726 16zm0-2c.703 0 1.274-.571 1.274-1.275v-9.45C14 2.571 13.429 2 12.726 2H3.275C2.57 2 2 2.571 2 3.275v9.45C2 13.429 2.57 14 3.275 14h9.45z' fill-rule='nonzero'/%3E %3Cpath d='M4.97 8.307l7.046 7.046a.5.5 0 0 0 .708-.707l-7.4-7.4a.5.5 0 0 0-.708 0l-3.97 3.97a.5.5 0 0 0 .708.707L4.97 8.307z' fill-rule='nonzero'/%3E %3Cpath d='M10.875 5.832l3.771 3.771a.5.5 0 0 0 .707-.707l-4.125-4.125a.5.5 0 0 0-.707 0l-4.19 4.19a.5.5 0 1 0 .707.707l3.837-3.836z' fill-rule='nonzero'/%3E %3Cpath d='M5.865 4.269a.894.894 0 1 1-1.787 0 .894.894 0 0 1 1.787 0'/%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  border-width: 0;
  border-radius: 14px;
  outline: none;
  box-shadow: 0 0 0 0.5px rgba(255, 255, 255, 0.15);
}

.isInDynamicBlock {
  overflow: hidden;

  border-radius: 8px;
}

.isInDynamicBlock .body {
  padding: 0 12px 12px;
}

.isInDynamicBlock::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  content: '';
}

/* VISIBILITY */

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
