@import '../../../../styles/vars.css';

.figure {
  position: relative;

  width: 100%;
  height: 224px;

  overflow: hidden;

  background-color: #000;
  border-color: #eeff85;
  border-style: solid;

  border-width: 4px 0;
  border-radius: 24px;

  pointer-events: none;

  @media $landscapeTablet {
    height: 320px;

    border-radius: 320px;
  }
}

.figure::after {
  position: absolute;

  border-radius: inherit;
  box-shadow: inset 0 0 64px 8px #000;

  content: '';

  inset: 0;
}

.rows {
  position: absolute;
  top: 50%;
  left: 0;

  display: flex;
  flex-flow: row nowrap;

  gap: 2px;

  width: 100%;

  transform: translate(0, -50%) rotate(15deg);

  @media $landscapeTablet {
    gap: 8px;
  }
}

.row {
  display: flex;
  flex-flow: column nowrap;

  gap: 2px;

  width: 16.6666666667%;
  min-width: 82px;

  transform: translateY(-33.333333%);

  animation-duration: 200s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  will-change: transform;

  @media $landscapeTablet {
    gap: 8px;
    min-width: 272px;
  }
}

.row:nth-child(odd) {
  animation-name: scrollUp;
}

.row:nth-child(even) {
  animation-name: scrollDown;
}

.image {
  display: block;

  width: 100%;

  background-repeat: repeat-y;
  background-position: center top;
  background-size: contain;

  aspect-ratio: 135 / 1216;
}

.figure svg {
  position: absolute;
  top: 10px;
  left: 50%;
  z-index: 10;

  width: 96px;
  height: 24px;

  transform: translateX(-50%);
}

@keyframes scrollUp {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(25%);
  }
}
@keyframes scrollDown {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-25%);
  }
}
