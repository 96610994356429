@import '../../styles/vars.css';

.root {
  margin-right: -16px;
  margin-left: -16px;
  padding: 32px 20px;

  font-size: 16px;

  font-family: $primaryFont;
  line-height: 22px;

  text-align: center;

  background-color: #f2f1ee;

  border-radius: 32px 32px 0 0;

  @media $mobile {
    margin-right: 0;
    margin-left: 0;
    padding: 80px 20px 0;

    font-size: 20px;
    line-height: 28px;
  }
}

.root:empty {
  height: 32px;
  padding: 0;

  @media $mobile {
    height: 40px;
  }
}

.root a {

  color: inherit;
  text-decoration: none;

  box-shadow: inset 0 -1px;
}

.root a:hover {
  color: #ff3b0e;
}

.root p {
  margin-top: 0;
  margin-bottom: 0;

  text-wrap: balance;
}

.root p + p {
  margin-top: 6px;
}

.note_credit {
  color: rgba(37, 0, 0, 0.4);

  font-size: 12px;
  line-height: 15px;
}

.note_caption {
  color: rgba(37, 0, 0, 1);
}

.note_caption b {
  font-weight: bold;
}

.center {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.center,
.default {
  margin-top: 25px;

  @media $mobile {
    margin-top: 45px;
  }
}

.note_caption,
.note_credit {
  width: 100%;
  max-width: 650px;
}
