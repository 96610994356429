/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-flow: column nowrap;

  gap: 80px;
  align-items: center;

  width: 100%;
}

.title {
  color: #eeff85;
  font-weight: 900;
  font-size: 24px;

  font-family: $secondaryFont;
  line-height: 110%;

  font-variation-settings: 'wdth' 120;
}

.featured {
  display: flex;
  flex-flow: column nowrap;

  gap: 40px;
  align-items: center;

  width: 100%;
  max-width: 375px;
  margin: 0;
  padding: 0;

  text-align: center;

  list-style: none;

  @media $landscapeTablet {

    gap: 120px;
    max-width: 642px;
  }
}

.feature {
  position: relative;

  width: 100%;

  color: #eeff85;
  font-weight: 900;
  font-size: 40px;

  font-family: $secondaryFont;
  line-height: 1;

  text-transform: uppercase;

  font-variation-settings: 'wdth' 72;

  @media $landscapeTablet {
    font-size: 56px;
  }
}

.feature svg {
  position: absolute;
}

.feature:nth-child(1) svg {
  top: -26px;
  left: 7.5%;

  width: 58px;

  @media $landscapeTablet {
    left: 18px;

    width: 79px;

    transform: translateY(-50%);
  }
}

.feature:nth-child(2) svg {
  top: 58px;
  right: 5%;

  width: 108px;

  @media $landscapeTablet {
    top: 33px;
    right: 64px;

    width: 147px;
  }
}

.feature:nth-child(3) svg {
  top: -40px;
  left: 0;

  width: 72px;

  @media $landscapeTablet {
    top: -56px;
    left: 90px;

    width: 96px;
  }
}

.feature:nth-child(4) svg {
  top: 16px;
  right: 0;

  width: 60px;

  @media $landscapeTablet {
    right: 48px;

    width: 83px;
  }
}

.feature:nth-child(5) svg {
  top: -28px;
  left: 7.5%;

  width: 85px;

  @media $landscapeTablet {
    top: -21px;
    left: 128px;

    width: 115px;
  }
}
