@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  flex-flow: column nowrap;

  gap: 8px;

  @media $landscapeTablet {
    flex-flow: row nowrap;
  }
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 320px;
  padding: 30px 32px 32px;

  background: #252525;

  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 40px;

  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

  @media $landscapeTablet {
    width: 50%;
  }
}

.block:hover {
  background-color: #2e2e2e;
}

.title {
  height: 52px;
  margin: 0 0 20px;

  color: #ccc;
  font-weight: 900;
  font-size: 24px;

  font-family: $secondaryFont;
  font-style: normal;
  line-height: 110%;

  text-align: center;

  font-variation-settings: 'wdth' 120;
}

.price {
  margin: 0;

  color: #ccc;
  font-weight: 900;
  font-size: 96px;

  font-family: $secondaryFont;
  font-style: normal;
  line-height: 110%;

  text-align: center;
  text-transform: uppercase;

  font-variation-settings: 'wdth' 120;
  font-feature-settings: 'ss11' on;
}

.price span {
  padding-left: 4px;

  opacity: 0.48;

  font-variation-settings: 'wdth' 56;
}

.subtitle {
  margin-top: -11px;
  margin-bottom: 20px;

  color: #eeff85;
  font-weight: 400;
  font-size: 16px;

  font-family: $primaryFont;
  line-height: 20px;

  opacity: 0.48;
}

.description {
  margin-bottom: 15px;

  color: #eeff85;
  font-weight: 400;
  font-size: 16px;

  font-family: $primaryFont;
  line-height: 20px;

  opacity: 0.48;
}

.footer {
  width: 100%;
  margin-top: auto;
}

.active {
  color: #eeff85;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 40px;
  padding: 9px 16px 7px;

  color: #fff;
  font-weight: 400;
  font-size: 14px;

  font-family: $primaryFont;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;

  background: rgba(249, 248, 245, 0.1);
  border: 1px solid #f9f8f5;
  border-radius: 120px;

  cursor: pointer;

  transition: opacity 0.3s ease-in-out;
}

.button:hover {
  opacity: 0.8;
}

.featured {
  position: relative;

  order: -1;

  color: #eeff85;

  background-color: #24251b;
  background-clip: padding-box;
  border: 3px solid transparent;

  @media $landscapeTablet {
    order: initial;
  }
}

.featured:hover {
  background-color: #2c2e1f;
}

.featured::before {
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  z-index: -1;

  background-image: linear-gradient(360deg, #eeff85 0%, #373a25 100%);

  border-radius: inherit;

  content: '';
}

.featured .title,
.featured .price {
  color: rgba(238, 255, 133, 1);
}

.featured .button {
  color: rgba(12, 0, 0, 1);

  background-color: rgba(238, 255, 133, 1);
  border-color: rgba(238, 255, 133, 1);
}
