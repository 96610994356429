@import '../../styles/vars.css';

.root {
  display: block;

  padding: 0;

  color: #fff;

  font-family: $primaryFont;
  line-height: 1;
  text-align: center;

  border-width: 0;
  outline: none;

  cursor: pointer;

  transition: background-color 0.15s ease-out;

  appearance: none;
  user-select: none;
}

.root.isLoading {
  background-color: transparent;
  box-shadow: none;
  cursor: default;

  pointer-events: none;
}

.root::-moz-focus-inner {
  border: 0;
}

.root[disabled] {
  cursor: default;

  opacity: 0.5;

  pointer-events: none;
}

.root > span:first-child {
  margin-right: 4px;
}

.root > span:last-child {
  margin-left: 4px;
}

.gold {
  color: rgba(238, 255, 133, 1);

  background: #250000;
}

.gold.isLoading {
  fill: rgba(238, 255, 133, 1);
}

.gold:hover {
  background-color: $bronze;

  @media (hover: none) {
    background-color: $brandColor;
  }
}

.gold:active {
  background-color: #856440;
}

.gray {
  color: #000;

  background-color: transparent;
  border: 1px solid #250000;
}

.gray.isLoading {
  fill: #545454;
}

.gray:hover {
  background-color: rgba(12, 0, 0, 0.02);

  @media (hover: none) {
    background-color: transparent;
  }
}

.gray:active {
  background-color: rgba(12, 0, 0, 0.02);
}

.sand {
  background: $sand;
}

.sand.isLoading {
  fill: $sand;
}

.sand:hover {
  background-color: #8a7654;

  @media (hover: none) {
    background-color: $sand;
  }
}

.sand:active {
  background-color: #7b6743;
}

.dark {
  color: #adadad;

  background-color: #3a3a3a;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.4);
}

.dark:hover {
  background-color: #2e2e2e;

  @media (hover: none) {
    background-color: #3a3a3a;
  }
}

.dark:active {
  background-color: #242424;
}

.dark.isLoading {
  fill: #3a3a3a;
}

.light {
  color: #000;

  background-color: #fff;
}

.light:hover {
  background-color: #ebebeb;

  @media (hover: none) {
    background-color: #fff;
  }
}

.light:active {
  background-color: #e3e3e3;
}

.light.isLoading {
  fill: #fff;
}

.black {
  color: rgba(238, 255, 133, 1);

  background-color: rgba(12, 0, 0, 1);
}

.black.isLoading {
  background-color: rgba(12, 0, 0, 1);

  fill: rgba(238, 255, 133, 1);
}

.black:hover {
  background-color: rgba(38, 32, 32, 1);

  @media (hover: none) {
    background-color: rgba(12, 0, 0, 1);
  }
}

.black:disabled,
.black:active {
  color: rgba(238, 255, 133, 0.48);

  background-color: rgba(12, 0, 0, 1);
  opacity: 1;
}

.red {
  color: rgba(255, 59, 14, 1);

  background-color: transparent;
  border-color: rgba(255, 59, 14, 1);
  border-style: solid;
  border-width: 1px;
}

.red:hover {
  background-color: rgba(255, 59, 14, 0.08);

  @media (hover: none) {
    background: rgba(255, 59, 14, 0.08);
  }
}

.red:active {
  background-color: #d44d4d;
}

.red.isLoading {
  fill: #141414;
}

.default {
  width: 100%;
  height: 42px;

  padding: 8px 18px 5px;

  font-weight: 400;

  font-size: 14px;
  line-height: 16px;

  text-transform: uppercase;

  border-radius: 24px;
}

.ghost {
  height: auto;
  padding: 0;

  color: #ff3b0e;
  font-weight: 400;
  font-size: 14px;

  font-family: $primaryFont;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  text-transform: uppercase;

  background-color: #0c0000;

  transition: opacity 0.15s ease-out;
}

.ghost:hover {
  opacity: 0.6;
}

.isInSpoiler svg {
  margin-top: -2px;
  margin-left: 6px;
}

.isInDropdown {
  font-family: $secondaryFont;
}

.sand[disabled] {
  opacity: 0.3;
}

.outline,
.simple {
  padding: 9px 4px;

  font-weight: 400;

  font-size: 14px;
  line-height: 20px;

  text-transform: uppercase;

  background-color: transparent;
  border-color: currentColor;
  border-style: solid;

  border-width: 1px;
  border-radius: 30px;
}

.simple {
  color: rgba(238, 255, 133, 1);
}

.simple:hover {
  background-color: rgba(238, 255, 133, 0.08);
}

.simple:hover svg {
  fill: rgba(238, 255, 133, 1);
}

.outline {
  color: rgba(204, 204, 204, 1);
}

.outline:hover {
  background-color: rgba(204, 204, 204, 0.08);
}

.loader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.loader svg {
  display: block;
}

/* APPERANCE */
.isFancy {
  letter-spacing: 0;
  text-transform: none;

  border-radius: 30px;
}

.isFancy.default {
  font-weight: 700;
  font-size: 14px;
}

.outlineSpoiler,
.simpleSpoiler {
  padding: 9px 4px;

  color: #250000;
  font-weight: 400;

  font-size: 14px;
  line-height: 20px;

  text-transform: uppercase;

  background-color: transparent;
  border-radius: 30px;
}

.outlineSpoiler {
  border: 1px solid rgba(37, 0, 0, 0.16);
}
