@import '../../styles/vars.css';

.root {
  font-weight: 400;
  font-family: $primaryFont;
}

.isInPopover .caption {
  padding-top: 0;
}

.root a {

  color: inherit;
  text-decoration: underline;

  box-shadow: none;
}

.root a:hover {
  color: #b88b58;
}

.caption {
  padding-top: 8px;

  color: rgba(37, 0, 0, 0.48);

  font-size: calc(14rem / 16);
  font-size: 14px;
  line-height: calc(18rem / 16);
  line-height: 20px;

  text-transform: uppercase;
}

.credit {
  padding-top: 8px;

  color: rgba(37, 0, 0, 0.48);

  font-size: 10px;
  line-height: normal;

  letter-spacing: 0.1px;

  @media $mobile {
    padding-top: 3px;
  }
}

.credit:first-child {
  padding-top: calc(3rem / 16);

  @media $mobile {
    padding-top: 3px;
  }
}

.video .caption,
.picture .caption {
  padding-top: calc(10rem / 16);

  @media $mobile {
    padding-top: 12px;
  }
}

.video .credit:first-child,
.picture .credit:first-child {
  padding-top: calc(9rem / 16);

  @media $mobile {
    padding-top: 11px;
  }
}

.picture .credit,
.picture .caption,
.video .credit,
.video .caption,
.desktop .credit,
.desktop .caption {
  max-width: 650px;
}

/* Dark theme */
.isDark .caption {
  color: #d9d9d9;
}

.isDark .credit {
  color: #757575;
}
