@import '../../styles/vars.css';

.root {
  font-weight: 400;
  font-size: 10px;

  font-family: $primaryFont;
  line-height: normal;
  letter-spacing: 0.1px;

  transform: translateZ(0);

  fill: inherit;
}

.rich,
.podcast,
.episode,
.simple,
.slide,
.card,
.game,
.isInMaterial {
  width: 100%;

  color: #250000;
}

.podcast {
  fill: currentColor;
}

.isInSearchItem {
  color: rgba(255, 255, 255, 0.7);
}

.slide.isInMediaBlock,
.card.isInMediaBlock,
.center.isInMediaBlock {
  text-align: left;
}

.center.half {
  @media $landscapeTablet {
    text-align: left;
  }
}

.episode,
.card,
.slide,
.game,
.center {
  @media $mobile {
    text-align: center;
  }
}

.podcastMaterial {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  text-align: center;
}

.light {
  color: rgba(255, 255, 255, 0.6);

  fill: currentColor;
}

.dark,
.isDark {
  color: rgba(0, 0, 0, 0.4);
}

.episode,
.card,
.slide,
.podcast {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  @media $portraitTablet {
    display: block;
  }
}

.hasSource {
  @media $mobileMax {
    display: flex;
    flex-flow: row wrap;
  }
}

.bookmark {
  position: absolute;
  right: -12px;
  bottom: -4px;
  z-index: 10;

  @media $mobile {
    display: none;
  }
}

.isCustom {
  color: var(--metaText);

  fill: var(--metaFill);
}

/* VISIBILITY */

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
