/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.root {
  display: flex;
  flex-flow: row nowrap;

  width: 100%;
  max-width: $layoutWidth;
  margin: 0 auto 4px;
}

.root:last-child {
  margin-bottom: 0;
}

.isSuperblock {
  max-width: $layoutWidth;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.block {
  position: relative;

  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;

  width: 100%;

  vertical-align: top;
}

.block + .block {
  margin-left: 4px;
}

.block.unknown {
  background-color: #a0a0a0;
}

.is1to1 {
  width: 100%;
}

.is1to2 {
  width: 50%;
}

.is1to3 {
  width: 33.33333%;
}

.is1to4 {
  width: 25%;
}

.block.isSuperblock {
  margin-bottom: 0;

  @media $mobile {
    padding-bottom: 0;
  }
}

.isWide {
  display: block;
}

.isWide .currentMaterial::before,
.isWide .currentMaterial article {
  display: none;
}

.isWide .currentMaterial {
  display: block;
}

.isWide:first-child .currentMaterial {
  margin-bottom: -15px;
}

.unknown {
  display: none;
}
