@import '../../styles/vars.css';

.root {
  max-width: 644px;
  margin: 15px auto 0;

  @media $portraitTablet {
    max-width: none;
    margin-top: 25px;
    margin-bottom: 20px;
  }
}

.hasFigcaption {
  margin-bottom: 15px;
}

.hasFigcaption + .root {
  @media $portraitTablet {
    margin-top: 25px;
  }
}

.hasFigcaption .item:last-child {
  margin-bottom: 0;
}

.items {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @media $portraitTablet {
    flex-direction: row;
  }
}

.item {
  flex-grow: 1;

  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  @media $portraitTablet {
    min-width: 0;
    max-width: none;
    margin-bottom: 0;
  }
}

.item + .item {
  @media $portraitTablet {
    margin-left: 8px;
  }
}

.figcaption {
  max-width: 970px;
  margin-right: auto;
  margin-left: auto;

  @media $landscapeTablet {
    padding-left: 320px;
  }
}

/* DISPLAY */
.default {
  @media $portraitTablet {
    max-width: 650px;
  }
}

.full {
  max-width: 1152px;

  @media $portraitTablet {
    padding: 0 4px;
  }
}

.super_full {
  max-width: none;
  @media $portraitTablet {
    padding: 0 4px;
  }
}

/* VISIBILITY */
.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
