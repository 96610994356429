@import '../../styles/vars.css';

.root {
  display: inline-block;

  font-weight: normal;

  font-family: $primaryFont;
}

.root::first-letter {
  text-transform: uppercase;
}
