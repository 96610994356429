@import '../../styles/vars.css';

.root {
  position: relative;

  width: 100%;
  padding: 20px;

  color: #fff;

  background: #4761e0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  box-shadow: 0 37px 37px rgba(0, 0, 0, 0.09), 0 9px 20px rgba(0, 0, 0, 0.1);
}

.root::after {
  position: absolute;

  width: 16px;
  height: 8px;

  background-image: url("data:image/svg+xml,%3Csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8 0 8 8H0l8-8Z' fill='%234057CA'/%3E%3Cpath d='M8 1s3.89 3.876 7 7H1l7-7Z' fill='%234761E0'/%3E%3C/svg%3E");

  content: '';
}

.isCenterBottom::after {
  bottom: 0;
  left: 50%;

  transform: translate(-50%, 100%) rotate(180deg);
}

.isLeftBottom::after {
  bottom: 0;
  left: 0;

  transform: translate(200%, 100%) rotate(180deg);
}

.isCenterTop::after {
  top: 0;
  left: 50%;

  transform: translate(-50%, -100%);
}

.isLeftTop::after {
  top: 0;
  left: 0;

  transform: translate(200%, -100%);
}

.body {
  font-size: 16px;
  font-family: $secondaryFont;
  line-height: 22px;
}

.body h3 {
  margin: 0 0 6px;

  font-weight: 700;

  font-size: inherit;
  line-height: inherit;
}

.body p {
  margin: 0;
}

.body p + p {
  margin-top: 6px;
}

.body a {
  color: inherit;
  text-decoration: none;

  box-shadow: inset 0 -1px;
}

.body a:hover {
  color: inherit;

  opacity: 0.75;
}

.footer {
  margin-top: 10px;
}

.footer button {
  color: #4761e0;
}
