@import '../../styles/vars.css';

.root {
  margin: 24px -16px;

  @media $mobile {
    margin: 16px auto;
  }
}

.root:last-child {
  margin-bottom: 0;
}

.root:first-child {
  margin-top: 0;
}

.container {
  position: relative;

  width: 100%;

  background-color: #f2f1ee;

  border-radius: 24px;

  @media $mobile {
    display: flex;
    flex-flow: column nowrap;
  }
}

.header {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  width: 100%;
  margin: 0 auto 24px;
  padding: 24px 16px 0;

  @media $mobile {
    padding: 24px 24px 0;
  }
}

.header h3 {
  margin: 0;

  font-weight: 900;
  font-size: 20px;

  font-family: $secondaryFont;
  line-height: 26px;

  font-variation-settings: 'wdth' 100, 'slnt' 0;

  @media $mobile {
    font-size: 24px;
    line-height: 26px;
  }
}

.body {
  display: none;

  width: 100%;
  padding: 0 16px;

  @media $mobile {
    padding: 0 24px;
  }
}

.spoiled .header {
  margin-bottom: 16px;
}

.spoiled .body {
  display: block;

  margin-bottom: 24px;
}

.footer {
  position: static;

  width: 100%;
  padding: 0 16px 24px;

  @media $mobile {
    padding: 0 24px 24px;
  }
}

.withoutTitle .footer {
  padding: 0;
}

.spoiled.withoutTitle .footer {
  padding: 0 24px 24px;
}

.withoutTitle .body {
  padding-top: 24px;
}

.spoiled .footer {
  position: sticky;
  bottom: 0;
  z-index: 10;

  width: auto;

  animation: spoilerSticky 500ms ease both;

  @media $mobile {
    position: static;

    width: 100%;
    margin-right: auto;
    margin-left: auto;

    animation: none;
  }
}

.spoiled .footer::after {
  position: absolute;
  top: -50px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  background-image: linear-gradient(
    180deg,
    rgba(242, 242, 238, 0) 0,
    rgba(242, 242, 238, 1) 54px,
    rgba(242, 241, 238, 1) 100%
  );

  border-radius: 0 0 24px 24px;

  content: '';
  pointer-events: none;

  @media $mobile {
    content: none;
  }
}

.icon {
  display: inline-block;

  width: 12px;
  height: 12px;
  margin-top: -2px;
  margin-left: 6px;

  vertical-align: middle;

  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.442 5.02a48.988 48.988 0 0 0-1.407 2.065c-.443-.629-.822-1.274-1.209-1.933a245.5 245.5 0 0 0-.126-.215c-.533-.906-1.093-1.832-1.85-2.698-.213-.49-.701-.732-1.142-.739-.49-.007-1.029.277-1.17.908-.128.534.089.95.305 1.242.092.124.196.242.282.34l.037.04c.1.115.173.202.225.283l.01.017.012.015c.56.74 1.06 1.573 1.58 2.44l.188.314c.574.955 1.182 1.937 1.906 2.8.15.252.356.446.621.54.28.1.558.066.79-.026.43-.17.778-.566.95-.994.598-1.009 1.248-1.938 1.91-2.88l.049-.069c.65-.926 1.313-1.868 1.925-2.889l.007-.011.006-.012c.185-.35.203-.715.083-1.036a1.284 1.284 0 0 0-.611-.68 1.202 1.202 0 0 0-.914-.093c-.322.102-.576.345-.717.69-.512.845-1.089 1.66-1.679 2.495l-.06.085Z' stroke='%23250000' stroke-linecap='round'/%3E%3C/svg%3E");
}

.spoiled .icon {
  transform: rotate(180deg);
}

.center {
  @media $mobile {
    max-width: 970px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 320px;
  }
}

@keyframes spoilerSticky {
  0% {
    transform: translateY(62px);
  }

  50% {
    transform: translateY(-16px);
  }

  100% {
    transform: translateY(0);
  }
}

/* VISIBILITY */

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
