@import '../../styles/vars.css';

.root {
  display: inline;

  margin-right: 15px;
}

.root:only-child {
  margin-right: 0;
}

.root > svg {
  display: inline-block;

  margin-right: 1px;

  color: inherit;

  vertical-align: top;

  fill: currentColor;
}

.root a {
  display: inline;

  color: inherit;

  text-decoration: underline;

  text-decoration-skip: auto;
}

.root a:hover {
  color: $brandColor;
}

.hasBullets {
  margin-right: 0;
}

.hasBullets:nth-child(n + 2)::before {
  display: inline-block;

  padding-right: 0.4em;
  padding-left: 0.4em;

  content: '\B7';
}

.hasSource {
  @media $mobileMax {
    width: 100%;
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.hasSource:first-child {
  @media $mobileMax {
    margin-top: 0;
  }
}

.hasSource:last-child {
  margin-bottom: 0;
}

.hasSource:nth-child(n + 2)::before {
  @media $mobileMax {
    display: none;
  }
}

.hasSource.listened::before,
.hasSource.read::before {
  @media $mobileMax {
    display: inline-block;
  }
}

.datetime,
.listened,
.read {
  @media $mobileMax {
    width: auto;
  }
}

.hasSource.datetime:first-child {
  @media $mobileMax {
    order: -2;
  }
}

.hasSource.listened,
.hasSource.read {
  @media $mobileMax {
    order: -1;

    margin-top: 0;
  }
}

.read svg {
  margin-top: -1px;
}
