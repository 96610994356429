@import '../../styles/vars.css';

.root {
  position: relative;
  z-index: $zIndex-1;

  display: inline-block;

  font-family: $secondaryFont;
}

.root.active .menu,
.root:hover .menu {
  visibility: visible;

  opacity: 1;
}

.root ul {

  width: 162px;
  margin: 0;
  padding: 3px 0;
  overflow: hidden;

  font-size: 14px;

  font-family: $secondaryFont;
  line-height: 26px;

  background: #fff;

  border-radius: 5px;
  box-shadow: 0 0 0 1px $silver, 0 5px 20px 0 rgba(0, 0, 0, 0.2);
}

.root a {
  display: block;

  padding: 2px 15px;

  color: #000;

  text-decoration: none;

  box-shadow: none;
}

.root a:hover {
  color: #000;

  background-color: #f7f7f7;
}

.isInAudioPanel .menu {
  right: 0;
  left: auto;

  @media $landscapeTablet {
    right: auto;
    left: 0;
  }
}

.menu {
  position: absolute;
  top: 100%;
  left: 0;

  padding-top: 6px;

  visibility: hidden;

  opacity: 0;
}

.menu::after {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  z-index: -1;

  content: '';
}
