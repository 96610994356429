/* stylelint-disable declaration-empty-line-before */
@import '../../styles/vars.css';

.root {
  position: relative;

  display: inline-block;

  width: 18px;
  height: 18px;

  vertical-align: middle;
}

.gold {
  color: $brandColor;
}

.root::before,
.root::after,
.frame {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.root {
  margin-top: -4px;
  margin-right: 1px;

  transform: translateY(1px);
}

.root::before,
.root::after,
.frame {
  border-radius: 50%;
  transform: scale(0);

  animation-duration: 1.05s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.frame {
  background: currentColor;

  animation-name: liveMiddle;
}

.root::before,
.root::after {
  border: 1px solid currentColor;

  content: '';
}

.root::before {
  animation-name: liveFirstWave;
}

.root::after {
  animation-name: liveSecondWave;
}

@keyframes liveMiddle {
  14% {
    transform: scale(0);
  }

  30% {
    transform: scale(0.22);
  }

  63% {
    transform: scale(0.22);
  }

  70% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes liveFirstWave {
  21% {
    transform: scale(0.1);
  }

  53% {
    transform: scale(0.33);
  }

  100% {
    transform: scale(0.66);

    opacity: 0;
  }
}

@keyframes liveSecondWave {
  21% {
    transform: scale(0.22);
  }

  53% {
    transform: scale(0.55);
  }

  100% {
    transform: scale(0.88);

    opacity: 0;
  }
}
