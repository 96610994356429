@import '../../styles/vars.css';

.root {
  display: block;

  height: 100%;
  margin: 0;
  padding: 0 1px 0 0;

  color: rgba(37, 0, 0, 1);
  font-weight: normal;
  font-size: 16px;

  font-family: $secondaryFont;
  line-height: 1;

  background-color: transparent;
  border-width: 0;
  outline: none;

  cursor: pointer;

  appearance: none;
}

.root::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}

.root:hover {
  opacity: 0.6;
}

.icon {
  display: inline-block;

  margin-top: -2px;
  padding-right: 8px;
  padding-left: 4px;

  vertical-align: middle;
}

.text {
  display: inline-block;

  vertical-align: middle;
}

.bookmark {
  fill: transparent;
}

.isActive {
  fill: currentColor;
}

.isInRich {
  font-family: $primaryFont;
}

.center {
  font-weight: 900;

  font-size: 48px;
  font-style: normal;
  line-height: 90%;
  text-transform: uppercase;

  font-variation-settings: 'wdth' 62, 'slnt' 0;

  @media $landscapeTablet {
    font-size: 72px;
  }
}
