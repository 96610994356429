@import '../../styles/vars.css';

.root {
  margin: 16px 0;

  background-color: $platinum;

  border-radius: 8px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 4px 0 0 #b78b5c;

  @media $portraitTablet {
    margin: 25px 0;
  }
}

.root:last-child {
  margin-bottom: 0;
}

.root:first-child {
  margin-top: 0;
}

.root a {
  display: block;

  padding: $gapSmall $gapSmall 13px;

  color: #000;

  font-size: 17px;
  line-height: 20px;
  text-decoration: none;

  box-shadow: none;

  transition: color 0.15s;

  @media $portraitTablet {
    padding: 15px 20px 17px;

    font-size: 19px;
    line-height: 23px;
  }
}

.root a:hover {
  color: #5c5c5c;
}

.header {
  margin: 0;
  padding: 14px 12px 3px;

  color: #7f7f7f;
  font-weight: 600;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 17px;

  letter-spacing: 1px;
  text-transform: uppercase;

  @media $portraitTablet {
    padding: 20px 20px 14px;

    font-size: 14px;
    line-height: 16px;
  }
}

.items {
  margin: 0;
  padding-left: 0;

  list-style: none;
}

.items li {
  padding: 0;
}

.items li + li {
  border-top: 1px solid #e6e6e6;
}

.rich .first {
  font-weight: 700;
  font-size: 16px;
  font-family: $secondaryFont;
  line-height: 18px;

  @media $portraitTablet {
    font-size: 18px;
  }
}

.center {
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;
}

.isInSlideMaterial:first-child {
  margin-top: 0;
}

.isInSlideMaterial:last-child {
  margin-bottom: 0;
}

/* VISIBILITY */

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
