@import '../../styles/vars.css';

.root {
  margin-bottom: calc(14rem / 16);

  font-size: calc(18rem / 16);

  font-family: $primaryFont;
  line-height: calc(24rem / 16);

  @media $mobile {
    margin: 0 0 20px;

    font-size: 20px;
    line-height: 28px;
  }
}

.root + .root {
  margin-top: calc(-14rem / 16);
  padding-top: calc(14rem / 16);

  @media $mobile {
    margin-top: -20px;
    padding-top: 20px;
  }
}

.container {
  position: relative;

  padding-left: 16px;

  @media $mobile {
    padding-left: 20px;
  }
}

.root + .root .container::before {
  top: -10px;
}

.container::before {
  position: absolute;
  top: 5px;
  bottom: 7px;
  left: 0;

  width: 2px;

  background-color: #ccc;

  content: '';

  @media $mobile {
    top: 4px;
    bottom: 6px;
  }
}

.root:last-child {
  margin-bottom: 0;
}

.root p {
  margin: 0;
}

.root a {

  color: inherit;
  text-decoration: none;

  box-shadow: inset 0 -1px $brandColor;
}

.root a:hover {
  color: $brandColor;
}

.center {
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;

  @media $landscapeTablet {
    max-width: 970px;
    padding-left: 320px;
  }
}
