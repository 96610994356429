/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zIndex-10;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  width: 100%;
  max-height: 0;
  overflow: hidden;

  opacity: 0;

  transition: opacity 0.15s ease-out;

  pointer-events: none;

  @media $portraitTablet {
    position: absolute;

    align-items: center;

    background-color: transparent;
  }
}

.container {
  @media $landscapeTablet {
    position: absolute;

    transform: translate3d(8px, 10px, 0);

    transition: transform 0.15s ease-out;
  }
}

.footnote {
  @media $landscapeTablet {
    width: 400px;
    margin: 0;

    pointer-events: auto;
  }
}

.root.active {

  height: 100%;
  max-height: none;
  overflow: visible;

  opacity: 1;

  pointer-events: auto;

  @media $landscapeTablet {
    pointer-events: none;
  }
}

.root.active .container {
  @media $landscapeTablet {
    width: 100%;
    max-width: 1242px;

    transform: translate3d(8px, 0, 0);
  }
}
