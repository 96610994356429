@import '../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  display: block;
  overflow: hidden;

  background-color: #f2f2f2;

  transition: background-color 250ms ease;

  @media $mobile {
    border-radius: inherit;
  }
}

.root img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.isLoaded {
  background-color: transparent;
}

.wrapper {
  display: block;

  width: 100%;

  background-repeat: no-repeat;
  background-size: 100% auto;
}

.fullscreen picture {
  cursor: zoom-in;
}

[data-input='mouse'] .wrapper {
  outline: none;
}

.dark {
  background-color: transparent;
}

.isStatic,
.isStatic .wrapper,
.isStatic .wrapper > div {
  height: 100%;
}

.isStatic img {
  position: static;

  height: 100%;
}
