@import '../../../styles/vars.css';

.root {
  width: 100%;
  margin: 0;
  padding: 9px 10px;

  font-size: 14px;

  font-family: $primaryFont;
  line-height: 18px;
  vertical-align: top;

  background-color: #fff;

  border-width: 0;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #e0e0e0;

  appearance: none;

  resize: none;

  @media $mobile {
    font-size: 18px;
    line-height: 21px;
  }
}

.root::placeholder {
  color: #999;

  opacity: 1;
}

.isInReaction {
  height: 102px;
}
