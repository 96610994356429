@import '../../styles/vars.css';

.root {

  margin-right: -16px;
  margin-left: -16px;
  overflow: hidden;

  font-family: $primaryFont;

  transform: translateZ(0);

  @media $mobile {
    margin-right: 0;
    margin-left: 0;
  }
}

.list {
  display: flex;
  flex-flow: row wrap;

  margin: 0 -17px;
  padding: 0;

  list-style: none;
}

.item {
  position: relative;

  height: 30px;
  padding-right: 17px;
  padding-left: 17px;

  color: #000;

  line-height: 1;
  text-align: center;

  transition: color 250ms ease;
}

.item:last-child {
  margin-right: 0;
}

.item:empty {
  display: none;
}

.isInTopbar::before {
  content: none;
}

.isInTopbar .item {
  height: 45px;
}

.center {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  padding: 0 0 56px;

  background-color: #f2f1ee;

  border-radius: 0 0 32px 32px;

  @media $landscapeTablet {
    padding: 40px 0 104px;
  }
}

.label {
  margin-bottom: 16px;

  color: rgba(37, 0, 0, 1);
  font-weight: 400;

  font-size: 14px;
  font-style: normal;
  line-height: 20px;

  text-transform: uppercase;
}

.center .list {
  flex-flow: column nowrap;

  gap: 12px;
  align-items: center;
  justify-content: center;

  max-width: 650px;

  @media $landscapeTablet {
    flex-flow: row wrap;

    gap: 0;
  }
}

.center .item {
  height: auto;
}
