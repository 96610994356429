@import '../../styles/vars.css';

.root {
  display: inline-block;

  font-weight: 600;
  font-size: 12px;

  font-family: $secondaryFont;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  vertical-align: middle;

  cursor: default;

  user-select: none;
}

.small {
  font-size: 12px;
  line-height: 12px;

  @media $landscapeTablet {
    line-height: 14px;
  }
}

.large {
  @media $landscapeTablet {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
  }
}

.dark {
  color: #000;
}

.light {
  color: #fff;
}

.gold {
  color: $brandColor;
}

.gray {
  color: #757575;
}

.inherit {
  color: inherit;
}

.custom {
  color: var(--tagColor);
}

.is1to1,
.is1to2,
.isInSuperBlock {
  @media $landscapeTablet {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
  }
}

.is1to3,
.is1to4 {
  @media $landscapeTablet {
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.5px;
  }
}

.slide,
.game {
  @media $mobile {
    width: 100%;

    text-align: center;
  }
}

.podcastMaterial {
  width: 100%;

  text-align: center;
}

.richRelated {
  font-size: 12px;
  line-height: 17px;

  @media $landscapeTablet {
    font-size: 14px;
    line-height: 20px;
  }
}

.slide,
.game,
.card,
.live,
.episode,
.podcast,
.rich,
.isInDynamicBlock {
  margin-bottom: 6px;

  line-height: 10px;

  @media $mobile {
    margin-bottom: 10px;
  }
}

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}

.isInBookRelated {
  margin-bottom: 6px;
}
