/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  min-height: calc(100vh - 70px);

  background-color: #121212;

  @media $mobile {
    min-height: calc(100vh - 77px);
  }
}

.header {
  margin-bottom: -6vw;
  padding-top: 80px;

  @media $mobile {
    margin-top: -2vw;
    padding-top: 74px;
  }
}

.header svg {
  display: block;

  width: 100%;
  height: auto;
}

.heading {
  padding: 0 12px;
}

.headerDesktop {
  display: none;

  @media $mobile {
    display: block;
  }
}

.headerMobile {
  @media $mobile {
    display: none;
  }
}

.nav {
  position: fixed;
  top: 8px;
  left: 50%;
  z-index: 20;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 360px;
  height: 56px;
  padding: 12px 20px;

  background: rgba(22, 22, 22, 0.72);

  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 104px;
  transform: translateX(-50%);

  backdrop-filter: blur(22px);

  transition: transform 350ms ease-out;
}

.nav.isHidden {
  transform: translateX(-50%) translateY(-120%);
}

.logo {
  display: block;

  width: 92px;
}

.wrapper {
  max-width: 825px;
  margin: 0 auto;
}

.title {
  max-width: 780px;

  color: #ebebeb;
  font-weight: 900;
  font-size: 40px;

  font-family: $secondaryFont;
  line-height: 1;

  text-align: center;
  text-transform: uppercase;

  font-variation-settings: 'wdth' 72;

  @media $landscapeTablet {
    font-size: 56px;
  }
}

.plans {
  display: flex;
  flex-flow: column nowrap;

  gap: 40px;
  align-items: center;

  padding: 40px 16px 80px;

  background-color: #121212;

  @media $landscapeTablet {
    padding: 70px 120px 120px;
  }
}

.variants {
  width: 100%;
  max-width: 916px;
}

.hero {
  width: 100%;
  padding: 0 24px;
}

.features {
  padding: 80px 16px;

  background-color: #0c0000;

  @media $landscapeTablet {
    padding: 120px 0 200px;
  }
}

.team {
  padding: 80px 16px;

  background-color: #121212;

  @media $landscapeTablet {
    padding: 120px 0 200px;
  }
}

.faq {
  padding: 80px 24px;

  background-color: #0c0000;

  @media $landscapeTablet {
    padding: 120px 0 200px;
  }
}

.contacts {
  flex-flow: column nowrap;

  gap: 16px;
  align-items: center;

  padding: 72px 0 80px;

  color: #eeff85;

  text-align: center;

  background-color: #121212;

  @media $landscapeTablet {
    padding: 162px 20px 120px;
  }
}

.contacts h3 {
  margin: 0;

  font-weight: 900;
  font-size: 20px;

  font-family: $secondaryFont;
  line-height: 110%;
  line-height: 110%;

  font-variation-settings: 'wdth' 120;

  @media $landscapeTablet {
    font-size: 24px;
  }
}

.contacts a {
  color: #eeff85;
  font-weight: 900;
  font-size: 40px;

  font-family: $secondaryFont;
  line-height: 1;
  text-transform: uppercase;

  font-variation-settings: 'wdth' 72;

  @media $landscapeTablet {
    font-size: 56px;
  }
}

.contacts p {
  margin: 0;
}

.auth svg {
  display: inline-block;

  width: 20px;
  height: 20px;
  margin-top: -4px;
  margin-right: 8px;
}
