@import '../../styles/vars.css';

.root {
  position: relative;

  margin: 15px 0;

  @media $mobile {
    margin: 25px 0 20px;
  }
}

.root:first-child {
  margin-top: 0;
}

.root:last-child {
  margin-bottom: 0;
}

.root figcaption {
  width: 100%;
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;

  font-family: $secondaryFont;
}

.container {
  position: relative;

  min-height: 170px;

  transition: min-height 350ms ease;
}

.overlay {
  position: absolute;
  top: 0;
  right: -20px;
  bottom: 0;
  left: -20px;
  z-index: 10;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  padding: 30px 20px;

  background-color: rgba(255, 255, 255, 1);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  transition: opacity 500ms ease, visibility 500ms ease;

  @media $mobile {
    right: 0;
    left: 0;
  }
}

.content {

  color: #fff;

  font-family: $secondaryFont;

  user-select: none;
}

.content p {
  display: block;

  width: 100%;
  max-width: 368px;
  margin-top: 0;
  margin-bottom: 12px;

  font-size: 14px;
  line-height: 18px;

  text-align: center;

  @media $mobile {
    margin-bottom: 24px;

    font-size: 16px;
    line-height: 22px;
  }
}

.button {
  width: 100%;
  max-width: 348px;
  margin: 0 auto;
}

.default {
  max-width: 650px;

  @media $mobile {
    margin-right: auto;
    margin-left: auto;
  }
}

.full {
  max-width: 980px;

  @media $mobile {
    margin-right: auto;
    margin-left: auto;
  }
}

.full .overlay {
  right: -20px;
  left: -20px;

  @media $mobile {
    right: 0;
    left: 0;
  }
}

.full .media {
  padding-right: 20px;
  padding-left: 20px;

  @media $mobile {
    padding-right: 0;
    padding-left: 0;
  }
}

.super_full {
  max-width: 100%;
}

.super_full .overlay {
  @media $mobile {
    right: -15px;
    left: -15px;
  }
}

.super_full .media {
  @media $mobile {
    padding-right: 15px;
    padding-left: 15px;
  }
}

/* STATE */
.visible .overlay {
  visibility: hidden;

  opacity: 0;

  pointer-events: none;
}

.visible .container {
  min-height: 0;
}

/* CONTEXT */
.isInCard .overlay,
.isInSlide .overlay {
  @media $landscapeTabletMax {
    right: -11px;
    left: -11px;
  }
}
