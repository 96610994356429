@import '../../../styles/vars.css';

.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 501;

  display: flex;

  width: 100%;
  height: 100%;
  overflow: auto;

  background-color: rgba(0, 0, 0, 0.9);
  visibility: hidden;

  opacity: 0;

  @media $mobile {
    z-index: 301;

    flex-direction: column;

    align-items: center;

    padding-bottom: 12px;

    transition: opacity 250ms ease, visibility 250ms ease;
  }
}

.root p {
  margin: 0;
}

.root p mark {
  white-space: normal;
}

.active {
  visibility: visible;

  opacity: 1;
}

.active .content {
  transform: scale(1) translateY(0);

  opacity: 1;
}

.header {
  margin-bottom: 16px;

  text-align: center;

  @media $mobile {
    margin-bottom: 20px;
  }
}

.header p {
  margin: 8px 0 0;

  font-size: 14px;

  font-family: $secondaryFont;
  line-height: 18px;

  @media $mobile {
    margin: 10px 0 0;

    font-size: 20px;
    line-height: 28px;
  }
}

.header h3 {
  font-weight: 700;
  font-size: 20px;
  font-family: $secondaryFont;
  line-height: 24px;

  font-variation-settings: 'wdth' 120;

  @media $mobile {
    font-size: 24px;
    line-height: 26px;
  }
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 100%;
  margin: auto;

  transform: scale(0.5) translateY(100px);

  opacity: 0;

  @media $mobile {
    transition: opacity 250ms ease, transform 250ms ease;
  }
}

.content p a {
  color: inherit;

  box-shadow: inset 0 -1px #b88b58;
}

.controls {
  margin: 20px auto 0;

  @media $mobile {
    margin-top: 24px;
  }
}

.footer {
  padding: 20px $gapMedium;

  text-align: center;
}

.dismiss {
  position: absolute;
  top: 22px;
  right: 22px;

  display: none;
  display: block;

  padding: 0;

  color: rgba(37, 0, 0, 1);

  background-color: transparent;
  border-width: 0;
  box-shadow: none;

  cursor: pointer;

  appearance: none;
  fill: none;
}

.dismiss svg {
  display: block;

  width: 21px;
  height: 21px;
}

.host {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex-3;

  display: flex;
  flex-direction: column;
  overflow-y: auto;

  background: rgba(0, 0, 0, 0.5);

  opacity: 0;

  transition: opacity 0.15s ease-out;

  pointer-events: none;
}

.host.isActive {

  opacity: 1;

  pointer-events: auto;
}

.container {
  flex-shrink: 0;

  width: 100%;
  padding: 40px 12px 12px;

  font-family: $secondaryFont;

  background: #eeff85;

  border-radius: 24px 24px 0 0;

  @media $mobile {
    padding: 44px 48px 32px;

    border: 1px solid rgba(37, 0, 0, 0.16);
    border-radius: 32px;
    box-shadow: 0 317px 127px rgba(0, 0, 0, 0.01),
      0 178px 107px rgba(0, 0, 0, 0.05), 0 79px 79px rgba(0, 0, 0, 0.09),
      0 20px 44px rgba(0, 0, 0, 0.1);
  }
}

.submit {
  margin-top: 10px;

  text-align: center;
}

.label {
  display: block;

  margin-bottom: 5px;

  font-weight: 600;

  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;

  text-align: left;

  @media $mobile {
    padding-left: 10px;

    font-size: 14px;
    line-height: 17px;
  }
}

.subtitle {

  color: rgba(37, 0, 0, 1);
  font-size: 16px;

  font-family: $primaryFont;
  line-height: 20px;
  text-align: center;

  @media $mobile {
    max-width: 410px;
  }
}

.subtitle a {
  text-decoration: underline;
}

.loading {
  padding: 6px 0;

  text-align: center;
}

.auth {
  margin: 0 auto;
}

.authIcon {
  display: inline-block;

  width: 21px;
  height: 20px;
  margin-top: -2px;
  margin-right: 4px;

  vertical-align: middle;
}

.separator {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  margin: 6px auto;

  color: rgba(37, 0, 0, 1);
  font-size: 16px;

  font-family: $primaryFont;
  line-height: 20px;

  cursor: default;

  user-select: none;

  @media $landscapeTablet {
    margin: 16px auto;
  }
}

.isSearch.root {
  position: static;

  background-color: transparent;

  opacity: 1;
}

.isSearch .content {
  margin-top: 0;

  transform: none;
}

.isSearch .dismiss {
  display: none;
}

.isTextError .content,
.isIdea .content,
.isSendMessage .content,
.isDeleteUser .content,
.isWrongBrowser .content,
.isPostAuth .content {
  max-width: 650px;
  margin-bottom: 0;

  @media $mobile {
    margin-bottom: auto;
  }
}

.isIdea .root,
.isSendMessage .root,
.isTextError .root {
  z-index: $zIndex-4;

  padding-right: 10px;
  padding-left: 10px;
}

.dismissOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: none;

  content: '';
}

/* MODALS */
.isAuth,
.isPostAuth,
.isWrongBrowser {
  position: static;

  @media $mobile {
    position: fixed;
  }
}

.isAuth .form {
  margin: 0 auto;
}

.isAuth .content {
  max-width: 650px;
}

.isAuth .dismiss,
.isPostAuth .dismiss,
.isWrongBrowser .dismiss {
  @media $mobileMax {
    top: 15px;
    right: 15px;

    display: block;

    color: rgba(37, 0, 0, 1);

    fill: none;
  }
}

.isAuth .container {
  margin: 0 auto;

  text-align: center;

  @media $mobile {
    padding: 44px 48px 32px;
  }
}

.email {
  margin-bottom: 8px;
}

.formField {
  min-height: 42px;
  padding: 13px 12px 11px;

  background-color: #000;

  border-style: none;
  border-radius: 8px;

  will-change: opacity, transform;
}

.formField.hasError,
.field.hasError {
  box-shadow: inset 0 0 0 1px #f95a5a;
}

.message {
  margin: 0 auto;
  padding-bottom: 16px;

  font-size: 16px;

  font-family: $primaryFont;
  line-height: 20px;

  text-align: center;

  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.message strong {
  display: block;

  margin: 8px 0;

  color: #250000;
  font-weight: 900;
  font-size: 24px;

  font-family: $secondaryFont;
  line-height: 110%;

  text-transform: uppercase;

  font-variation-settings: 'wdth' 80;
}

.link {
  position: absolute;
  top: 24px;
  left: 24px;

  padding: 0;

  color: #250000;
  font-weight: 400;
  font-size: 14px;

  font-family: $primaryFont;
  line-height: 20px;
  text-transform: uppercase;

  background-color: transparent;
  border-width: 0;

  cursor: pointer;

  appearance: none;
}

.link:hover {
  color: #250000;

  opacity: 0.46;
}

.error {
  display: block;

  width: 218px;
  margin: 15px auto 0;

  color: #f95a5a;
  font-size: 13px;

  font-family: $primaryFont;
  line-height: 1;

  text-align: center;
}

.error a {

  color: inherit;
  text-decoration: underline;
}

.group {
  display: flex;
  flex-flow: column nowrap;

  gap: 8px;
}

.iconLogin {
  display: block;

  width: 80px;
  height: 80px;
}

.iconLogin svg {
  display: block;
}

.headerContent {
  display: flex;
  flex-flow: column nowrap;

  gap: 8px;
  align-items: center;

  margin-bottom: 16px;
  padding-bottom: 24px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.isReaction,
.isPostAuth,
.isWrongBrowser,
.isAuth {
  @media $mobileMax {
    margin-top: auto;
  }
}

.textarea textarea {
  display: block;

  width: 100%;
  height: 106px;
  margin: 0;
  padding: 8px;

  font-size: 16px;

  font-family: $primaryFont;
  line-height: 18px;

  background-color: transparent;

  border-width: 0;
  border-radius: 4px;
  outline-width: 0;
  box-shadow: inset 0 0 0 1px rgba(37, 0, 0, 1);

  appearance: none;

  resize: none;
}
