/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  position: relative;
  top: 0;
  z-index: 200;

  display: flex;
  flex-flow: row wrap;
  align-items: center;

  font-family: $secondaryFont;

  user-select: none;
}

.logo {
  position: relative;

  display: block;

  width: 100%;
  overflow: hidden;

  aspect-ratio: 368 / 61;
}

[data-route='account'] .logo {
  overflow: visible;

  @media $landscapeTablet {
    aspect-ratio: 368 / 37;
  }
}

[data-route='material'] .logo {
  @media $landscapeTablet {
    aspect-ratio: 368 / 37;
  }
}

.logo a {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 100%;

  color: #ff3b0e;
}

.logo a:hover {
  color: #ff491f;
}

.logo svg {
  display: block;
}

.buttons {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zIndex-1;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  font-size: 12px;
  line-height: 1;

  letter-spacing: 1px;

  @media $wideDesktop {
    font-size: 14px;
  }
}

.buttons .item::after {
  @media $landscapeTablet {
    content: '';
  }
}

.buttons .item:last-child::after {
  display: none;
}

.slogan {
  width: 100%;

  color: #fff;
  font-weight: 600;

  font-size: 14px;
  line-height: 45px;

  letter-spacing: 1px;
  text-transform: uppercase;
}

.nav {
  position: absolute;
  top: 58px;
  right: 0;
  left: 0;

  height: 0;
  padding: $gapSmall $gapSmall 4px;
  overflow: hidden;

  background-color: #040404;

  visibility: hidden;

  opacity: 0;

  transition: height 0.2s ease, visibility 0.2s ease, opacity 0.2s ease;

  @media $landscapeTablet {
    position: static;

    height: auto;
    padding: 0;
    overflow: visible;

    background-color: transparent;
    transform: translate3d(0, 0, 0);

    visibility: visible;

    opacity: 1;

    transition: none;
  }
}

.item {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 0;

  color: rgba(255, 59, 14, 1);
  font-weight: 400;
  font-size: 14px;

  font-family: $primaryFont;
  line-height: 20px;
  text-transform: uppercase;

  background: transparent;
  border: 0;

  cursor: pointer;

  fill: currentColor;
}

.isMute:not(.isItemActive) a {
  background-image: linear-gradient(
    275deg,
    #fff -80%,
    rgba(255, 255, 255, 0) 100%
  );
  background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent;

  @media $portraitTablet {
    background-image: linear-gradient(
      270deg,
      #fff -54%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.isMute:not(.isItemActive) a:hover {
  opacity: 0.7;
}

.menu a {
  display: block;

  padding: 0 6px;

  color: #fff;

  @media $portraitTablet {
    padding: 14px 10px;
  }

  @media $wideDesktop {
    padding: 14px 15px;
  }
}

.isItemActive a,
.isItemActive:hover a,
.isItemActive:focus a {
  color: #dcad76;
}

.itemSearch {
  color: #737373;

  @media $landscapeTablet {
    color: #fff;
  }
}

.itemFeed {
  padding-right: 12px;

  @media $landscapeTabletMax {
    display: none;
  }
}

.itemProfile {
  position: relative;
  z-index: 10;

  padding-bottom: 2px;

  color: #fff;

  cursor: default;
}

.profileControl {
  position: relative;

  display: flex;
  flex-flow: row nowrap;

  align-items: center;

  margin: 0;
  padding: 0;

  color: rgba(255, 59, 14, 1);

  background-color: transparent;
  border-width: 0;

  cursor: pointer;

  appearance: none;
}

.profileControl::after {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;

  content: '';
}

.dropdown {
  width: 100%;
  margin: 0;
  padding: 0 20px;

  color: #000;
  font-weight: normal;

  font-size: 18px;
  line-height: 26px;

  letter-spacing: 0;
  text-transform: none;

  list-style: none;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
}

.dropdownItem a,
.dropdownItem button {
  padding: 14px 0 17px;
}

.dropdownItem a {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath stroke='%23E6E6E6' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M2.5 3l9 9-9 9'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center right;
}

.dropdownItem a:hover,
.dropdownItem button:hover {
  color: #999;
}

.dropdownItem:first-child {
  padding-top: 15px;
  padding-bottom: 14px;

  border-bottom: 3px solid #000;
}

.dropdownItem + .dropdownItem {
  border-top: 1px solid #e0e0e0;
}

.dropdownItem .notify {
  position: static;

  display: inline-block;

  width: 9px;
  height: 9px;
  margin-left: 7px;

  vertical-align: middle;

  border-width: 0;
}

.email {
  font-weight: 700;
}

.button {
  width: 100%;
  margin: 0;
  padding: 0;

  text-align: left;

  background-color: transparent;

  border-width: 0;

  cursor: pointer;

  appearance: none;
}

.menu {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;

  padding-top: 15px;
  padding-bottom: 13px;

  font-size: 20px;
  line-height: 20px;

  border-top: 1px solid #323232;

  @media $portraitTablet {
    flex-basis: 60%;
    flex-flow: row wrap;
    flex-grow: 1;

    padding-top: 0;
    padding-bottom: 0;

    font-size: 12px;
    line-height: 17px;

    border-top: 0;
  }

  @media $wideDesktop {
    font-size: 14px;
  }
}

.notify {
  position: absolute;
  top: -2px;
  right: 1px;
  z-index: 1;

  width: 7px;
  height: 7px;

  background: #d09959;

  border: 1px solid #000;
  border-radius: 50%;
}

.mobileNav {
  padding-top: 2px;
  @media $landscapeTablet {
    display: none;
  }
}

.menuAdditional {
  padding: 22px 0;

  font-size: 16px;
  line-height: 22px;

  text-align: center;

  border-top: 1px solid #323232;
  border-bottom: 1px solid #323232;
}

.menuAdditional a {
  color: rgba(255, 255, 255, 0.5);
}

.menuAdditional:last-child {
  margin-bottom: 8px;
}

.menuAdditionalItem {
  margin: 0 6px;
}

.switcher {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 12px 4px;

  color: rgba(255, 255, 255, 0.5);

  font-size: 16px;

  @media $landscapeTablet {
    position: absolute;
    top: 10px;
    left: 0;

    padding: 0;
  }
}

.isNavOpened .nav {
  @media $landscapeTabletMax {
    height: calc(100vh - 60px);
    min-height: 500px;
    padding-bottom: 34px;
    overflow-y: auto;

    visibility: visible;

    opacity: 1;
  }
}

.isSearchOpened .itemSearch {
  color: #fff;
}

.marker {
  position: absolute;
  top: 6px;
  right: 3px;

  width: 6px;
  height: 6px;

  background-color: #fc4d51;

  border-radius: 6px;

  @media $landscapeTablet {
    top: 13px;
    right: 12px;

    width: 4px;
    height: 4px;
  }
}

[data-modal='search'] .logo,
[data-modal='search'] .nav,
[data-modal='search'] .itemProfile,
[data-modal='search'] .itemLang {
  visibility: hidden;

  opacity: 0;
}

[data-modal='search'] .itemSearch::after,
[data-modal='search'] .itemFeed,
[data-modal='search'] .itemAuth {
  display: none;
}

[data-modal='search'] .itemSearch {
  @media $landscapeTablet {
    transform: scale(2.2);
  }
}

[data-modal='loginHeader'] .aside,
[data-modal='loginHeader'] .switcher,
[data-modal='loginHeader'] .back,
[data-modal='wrongBrowser'] .aside,
[data-modal='wrongBrowser'] .back,
[data-modal='wrongBrowser'] .switcher,
[data-modal='postAuth'] .aside,
[data-modal='postAuth'] .back,
[data-modal='postAuth'] .switcher {
  @media $mobileMax {
    display: none;
  }
}

.aside {
  position: relative;

  display: flex;
  flex-flow: row nowrap;

  gap: 48px;
  align-items: center;

  width: 100%;
  padding: 12px 4px;

  @media $landscapeTablet {
    position: absolute;
    top: 0;
    right: 0;

    gap: 12px;

    width: auto;
    padding: 0;
  }
}

.membership {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 36px;
  padding: 10px 15px 8px;

  color: rgba(238, 255, 133, 1);
  font-size: 14px;

  font-family: $primaryFont;

  text-transform: uppercase;

  background: #5e5d31;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 20px;

  @media $landscapeTablet {
    width: auto;
    height: 40px;
  }
}

.membership::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: -1;

  background-image: linear-gradient(360deg, #c5d16d 0%, #828546 100%);

  border-radius: inherit;

  content: '';
}

.membership:hover {
  background-color: #606736;
}

/* TODO */

.itemSearch {
  outline-width: 0;
}

.back {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 10;

  color: #ff3b0e;
  font-weight: 400;
  font-size: 14px;

  font-family: $primaryFont;
  line-height: 20px;
  text-transform: uppercase;

  transform: translate(-50%, 22px);

  @media $landscapeTablet {
    top: 10px;

    transform: translate(-50%, 0);
  }
}

.item {
  display: flex;
  flex-flow: row nowrap;

  padding: 10px;
}

.item svg {
  display: inline-block;

  width: 20px;
  height: 20px;
  margin-top: -4px;
  margin-right: 8px;
}

.item:hover svg {
  fill: currentColor;
}

.item:hover svg mask {
  fill: none;
}

[data-pathname='/'] .item {
  @media $landscapeTabletMax {
    position: absolute;
    right: 8px;
    bottom: 100%;
  }
}
