/* stylelint-disable declaration-empty-line-before */
@import '../../../styles/vars.css';

.root {
  position: fixed;
  right: 16px;
  bottom: 8px;
  left: 16px;
  z-index: 400;

  @media $mobile {
    right: 4px;
    left: 4px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
}

.container {
  width: 100%;
  height: 56px;
  padding: 9px 20px 7px;

  color: #fff;

  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.08) 0%,
      rgba(0, 0, 0, 0.08) 100%
    ),
    rgba(249, 248, 245, 0.24);
  border-radius: 56px;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);

  backdrop-filter: blur(90px);

  @media $mobile {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    max-width: 1912px;
    height: 40px;
    padding: 0;
  }
}

.text {
  max-width: 220px;
  padding-bottom: 1px;

  font-weight: 400;
  font-size: 14px;

  font-family: $primaryFont;
  line-height: 20px;

  text-transform: uppercase;

  @media $mobile {
    max-width: none;
    padding-top: 2px;
    padding-bottom: 0;
  }
}

.text a {
  color: #fff;
  white-space: nowrap;
}

.text a::after {
  position: absolute;

  border-radius: 50px;

  content: '';

  inset: 0;
}

.dismiss {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  margin: 0;
  padding: 10px 20px 12px;

  color: #fff;

  background-color: transparent;
  border: 0;
  border-radius: 0;

  cursor: pointer;

  appearance: none;

  @media $mobile {
    padding: 10px 16px;
  }
}

.dismiss svg {
  display: block;

  width: 20px;
  height: 20px;

  fill: transparent;
}

.dismiss:hover svg {
  fill: #fff;
}
