/* stylelint-disable declaration-empty-line-before */
@import '../../styles/vars.css';

.root {
  display: flex;
  flex-flow: column nowrap;

  min-height: 100dvh;

  background-color: $bgColor;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  position: relative;
  z-index: $zIndex-2;

  width: 100%;
  max-width: $layoutWidth;
  margin: 0 auto;
  padding: 4px;

  @media $landscapeTablet {
    padding: 8px 4px;
  }
}

[data-route='account'] .header {
  z-index: 10;

  padding-bottom: 64px;

  @media $landscapeTablet {
    padding-bottom: 0;
  }
}

[data-route='material'] .header {
  padding-bottom: 7px;

  @media $landscapeTablet {
    padding-bottom: 0;
  }
}

[data-modal='loginHeader'] [data-route='membership'] {
  background-color: #0c0000;
}

[data-route='membership'] {
  background-color: #eeff85;
}

[data-route='membership'] .content,
[data-route='account'] .content {
  max-width: none;
  padding: 0;
}

.content {
  position: relative;
  z-index: $zIndex-1;

  flex-grow: 1;

  width: 100%;
  min-height: calc(100dvh - 140px);
  padding: 0 4px;

  @media $mobile {
    max-width: $layoutWidth;
    margin-right: auto;
    margin-left: auto;
  }
}

[data-route='screen'] .content {
  min-height: auto;
}

.controls {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: $zIndex-6;

  @media $mobile {
    right: 15px;
    bottom: 15px;
    z-index: $zIndex-3;
  }
}

.banner {
  position: relative;
  z-index: $zIndex-5;

  width: 100%;
  height: 250px;

  overflow: hidden;

  line-height: 0;

  background-color: #000;

  border-bottom: 1px solid #2b2b2b;

  @media $landscapeTablet {
    z-index: $zIndex-2;
  }
}

.footer {
  margin-top: 50px;

  @media $mobile {
    margin-top: 64px;
  }
}

[data-gdpr-panel='true'] .controls {
  @media $mobile {
    margin-bottom: 46px;
  }
}

[data-gdpr-panel='true'] .footer {
  margin-bottom: 44px;

  @media $mobile {
    margin-bottom: 46px;
  }
}

[data-modal='search'] .controls {
  z-index: -1;
}

[data-modal='search'] .content,
[data-modal='search'] .footer {
  display: none;
}

[data-modal='search'] .header {
  z-index: $zIndex-4;

  @media $landscapeTablet {
    height: 78px;
  }
}

.skipLink {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  overflow: hidden;

  border: 0;

  clip: rect(0 0 0 0);
}

[data-nav='true'] .content,
[data-nav='true'] .footer {
  @media $landscapeTabletMax {
    display: none;
  }
}

[data-nav='true'] .controls {
  z-index: $zIndex-3;
}

[data-modal='loginHeader'] .content,
[data-modal='loginHeader'] .footer,
[data-modal='loginBookmark'] .content,
[data-modal='loginBookmark'] .footer,
[data-modal='postAuth'] .content,
[data-modal='postAuth'] .footer,
[data-modal='wrongBrowser'] .content,
[data-modal='wrongBrowser'] .footer,
[data-modal='sendMessage'] .content,
[data-modal='sendMessage'] .footer {
  @media $mobileMax {
    display: none;
  }
}

[data-modal='idea'],
[data-modal='deleteUser'] {
  @media $mobileMax {
    overflow: hidden;
  }
}

[data-input='mouse'] button,
[data-input='mouse'] input,
[data-input='mouse'] textarea,
[data-input='mouse'] a {
  outline: none;
}
