@import '../../styles/vars.css';

.root {

  width: 100%;
  margin: 0 0 16px;

  font-weight: 900;
  font-size: 24px;

  font-family: $secondaryFont;
  line-height: 110%;

  font-variation-settings: 'wdth' 100, 'slnt' 0;

  @media $mobile {
    width: auto;
  }

  @media $portraitTablet {
    margin-bottom: 24px;

    font-size: 48px;
    line-height: 50px;

    font-variation-settings: 'wdth' 130, 'slnt' 0;
  }
}

.root:last-child {
  margin-bottom: 0;
}

.root span {
  display: block;

  margin-top: 4px;

  font-weight: normal;
  font-size: 16px;

  font-family: $primaryFont;
  line-height: 20px;

  font-variation-settings: 'wght' 400, 'slnt' 0;

  @media $portraitTablet {
    margin-top: 12px;

    font-size: 24px;
    line-height: 32px;
  }
}

.featured {
  @media $landscapeTabletMax {
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.3px;
  }
}

.featured span {
  @media $landscapeTabletMax {
    display: block;

    padding-top: 2px;

    font-size: 18px;
    line-height: 24px;
  }
}

.isInMediaBlock {
  text-align: left;

  @media $landscapeTablet {
    margin-bottom: 18px;
  }
}

.isInMediaBlock:not(.featured) {
  font-size: 19px;
  line-height: 23px;

  @media $landscapeTablet {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
}

.isInMediaBlock:not(.featured) span {
  font-size: 20px;

  @media $landscapeTablet {
    font-size: 1.35rem;
  }
}

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
