@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  -webkit-tap-highlight-color: transparent;
}

.link {
  color: inherit;
}

.isMobile {
  aspect-ratio: 1;
}

.is1to1 {
  height: 420px;
}

.is1to2 {
  height: 720px;
}

.is1to3 {
  height: 480px;
}

.is1to4 p {
  height: 360px;
}

.picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.picture img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center center;
}
