/* stylelint-disable declaration-empty-line-before */
@import '../../../../styles/vars.css';

.empty .feed {
  min-height: calc(100vh - 100px);

  background-color: #f2f2f2;

  border-radius: 10px;
}

.feed {
  max-width: 510px;
  margin: 0 auto;

  background-color: #0c0000 !important;

  @media $landscapeTablet {
    max-width: none;
  }
}

.header {
  margin: 0 10px 16px;
  padding-top: 14px;

  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;

  font-family: $secondaryFont;
  line-height: 22px;
  letter-spacing: 0;

  text-align: center;

  border-top: 1px solid #323232;

  @media $landscapeTablet {
    display: none;
  }
}

.header + .footer {
  visibility: hidden;
}

.item + .item .block {
  margin-top: 4px;
}

.banner {
  margin: 30px auto 15px;

  @media $desktop {
    margin: 40px auto 18px;
  }
}

.timestamp {
  margin: 24px 8px;

  color: #CCC;
  font-weight: 400;
  font-size: 14px;

  font-family: $primaryFont;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0;

  text-align: center;
  text-transform: uppercase;

  @media $landscapeTablet {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

.wrapper {
  max-width: 825px;
  margin: 0 auto;

  @media $landscapeTablet {
    max-width: 100%;
  }
}

.container {
  border-radius: 10px;

  @media $landscapeTablet {
    /* padding: 25px 10px 45px; */
  }
}

.container + .container {
  margin-top: 15px;
}

.control {
  max-width: fit-content;
  margin: 0 auto;
  padding: 0;
}

.footer {
  max-width: 510px;
  margin: 0 auto;
  padding: 24px 0;

  text-align: center;

  @media $landscapeTablet {
    max-width: fit-content;
    margin: 0 auto;
    padding: 48px 0;
  }
}

.informer {
  position: relative;

  display: flex;
  flex-flow: column nowrap;

  width: 100%;
  margin-bottom: 12px;
  padding: 11px 12px;

  font-weight: normal;

  background-color: #fff;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @media $landscapeTablet {
    display: none;
  }
}

.informer:empty,
.isInSearch .informer {
  display: none;
}
