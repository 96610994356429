@import '../../styles/vars.css';

.root {
  display: block;

  margin: 18px 0 15px;
  padding: 12px 11px 17px;

  color: #000;

  font-size: 16px;
  line-height: 20px;

  background-color: $platinum;
  border-radius: 2px 8px 8px 2px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), inset 4px 0 0 0 #b78b5c;

  @media $portraitTablet {
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 18px 16px 16px;
  }
}

.root:hover,
.root:visited {
  background-color: #ededed;
}

.root blockquote {
  margin: 0;
}

.root a {

  color: inherit;
  text-decoration: none;
}

.text {
  font-family: $secondaryFont;
}

.text p {
  margin: 0;

  font-size: 16px;
  line-height: 22px;

  @media $portraitTablet {
    font-size: 18px;
    line-height: 26px;
  }
}

.root p + p {
  margin-top: 12px;
}

.root footer {
  margin-top: 12px;
  padding-top: 10px;

  border-top: 1px solid #d6d6d6;

  @media $portraitTablet {
    margin-top: 18px;
    padding-top: 12px;
  }
}

.origin {

  color: #292929;
  font-weight: bold;
  font-size: 16px;

  font-family: $secondaryFont;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;

  pointer-events: none;

  @media $portraitTablet {
    font-size: 18px;
    line-height: 26px;
  }
}

.center {
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;
}

/* VISIBILITY */

.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}

/* Dark theme */
.isDark,
.isDark:hover {
  color: inherit;

  background-color: #262626;
}

.isDark .origin {
  color: #b8b8b8;
}

.isDark footer {
  border-top-color: #3d3d3d;
}
