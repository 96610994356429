@import '../../styles/vars.css';

.root {
  margin: 15px 0;

  @media $mobile {
    margin: 25px auto 20px;
  }
}

.card .table,
.slide .table {
  margin-right: -11px;
  margin-left: -11px;

  @media $landscapeTablet {
    margin-right: 0;
    margin-left: 0;
  }
}

.card .wrapper::after,
.slide .wrapper::after {
  right: -11px;
}

.root:first-child {
  margin-top: 0;
}

.root:last-child {
  margin-bottom: 0;
}

.table {
  position: relative;

  margin: 0 -20px;

  overflow-y: hidden;

  font-family: $secondaryFont;

  border-color: rgba(232, 232, 232, 1);
  border-style: solid;

  border-width: 1px 0;

  @media $mobile {
    margin: 0;

    border-width: 1px;
    border-radius: 3px;
  }
}

.tracks {
  display: table;

  width: 100%;

  @media $mobile {
    table-layout: fixed;
  }
}

.wrapper {
  position: relative;
}

.wrapper::after {
  position: absolute;
  top: 0;
  right: -20px;
  bottom: -20px;
  z-index: 100;

  display: block;

  width: 30px;
  height: 100%;

  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 71%
  );

  content: '';

  @media $mobile {
    content: none;
  }
}

.default {
  max-width: 650px;
}

.full {
  max-width: 980px;
}

.row {
  z-index: 1;

  display: table-row;
}

.row:last-child .cell {
  border-bottom: 0;
}

.row:first-child .cell:first-child {
  @media $mobile {
    border-radius: 2px 0 0;
  }
}

.row:first-child .cell:last-child {
  @media $mobile {
    border-radius: 0 2px 0 0;
  }
}

.row:last-child .cell:last-child {
  @media $mobile {
    border-radius: 0 0 2px;
  }
}

.row:last-child .cell:first-child {
  @media $mobile {
    border-radius: 0 0 0 2px;
  }
}

.cell {
  display: table-cell;

  min-height: 32px;
  padding: 6px;
  overflow: hidden;

  font-size: 12px;
  line-height: 16px;

  vertical-align: top;

  background-color: #fff;

  border-right: 1px solid rgba(232, 232, 232, 1);
  border-bottom: 1px solid rgba(232, 232, 232, 1);

  @media $mobile {
    padding: 9px 12px 8px;

    font-size: 14px;
    line-height: 19px;
  }
}

.cell:last-child {
  padding-right: 20px;

  border-right: 0;

  @media $mobile {
    padding-right: 12px;
  }
}

.cell a {

  color: inherit;
  text-decoration: none;

  box-shadow: inset 0 -1px #000;
}

.cell a:hover {
  color: #b88b58;

  box-shadow: inset 0 -1px #b88b58;
}

.cell p {
  margin: 0;
}

.cell p + p {
  margin-top: 8px;
}

.fixedColumn {
  position: sticky;
  left: 0;
  z-index: 50;

  @media $mobile {
    position: static;
  }
}

.fixedColumn::after {
  position: absolute;
  top: 0;
  right: -15px;
  bottom: 0;

  width: 15px;

  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.04) 0%,
    rgb(0, 0, 0, 0) 100%
  );

  content: '';

  @media $mobile {
    content: none;
  }
}

.white {
  background-color: #fff;
}

.gray {
  background-color: #f5f5f5;
}

.pink {
  background-color: #feeeee;
}

.blue {
  background-color: #e6f3ff;
}

.yellow {
  background-color: #fffde6;
}

.orange {
  background-color: #fff9f2;
}

.green {
  background-color: #e7f2ef;
}

.violet {
  background-color: #f4f0f8;
}

.header {
  max-width: 340px;
  padding: 9px 6px;

  font-weight: 600;

  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  vertical-align: top;

  border-right: 1px solid rgba(232, 232, 232, 1);
  border-bottom: 1px solid rgba(232, 232, 232, 1);

  @media $mobile {
    max-width: none;
    padding: 9px 12px;

    line-height: 11px;
  }
}

.s {
  min-width: 120px;

  @media $mobile {
    width: 18%;
    min-width: 0;
  }
}

.m {
  min-width: 200px;

  @media $mobile {
    width: 30%;
    min-width: 0;
  }
}

.l {
  min-width: 336px;

  @media $mobile {
    width: 50%;
    min-width: 0;
  }
}

.xs {
  width: 50px;
  min-width: 50px;
}
