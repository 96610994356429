@import '../../../styles/vars.css';

.root {
  position: relative;

  min-height: calc(100vh - 100px);
  padding: 0 8px 20px;

  @media $landscapeTablet {
    padding: 0 20px 134px;
  }
}

.root::after {
  position: absolute;
  right: 10px;
  bottom: 0;
  left: 10px;

  border-bottom: 1px solid rgba(255, 255, 255, 0.16);

  content: '';
}

.container {
  display: flex;
  flex-flow: column nowrap;

  gap: 72px;

  max-width: 650px;
  margin: 0 auto;
}

.blocks {
  display: flex;
  flex-flow: column nowrap;

  gap: 16px;
}

.header {
  margin-bottom: 30px;

  text-align: center;

  @media $mobile {
    margin-bottom: 45px;
  }
}

.title {
  margin: 0 0 10px;

  font-weight: 900;
  font-size: 20px;

  font-family: $secondaryFont;
  line-height: 24px;

  text-align: center;

  @media $mobile {
    font-size: 24px;
    line-height: 110%;

    font-variation-settings: 'wdth' 120;
  }
}

.block {
  padding: 32px 24px 48px;

  color: rgba(238, 255, 133, 1);
  font-size: 14px;

  font-family: $primaryFont;
  line-height: 18px;

  background: #141414;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 32px;
  box-shadow: 0 317px 127px rgba(0, 0, 0, 0.01),
    0 178px 107px rgba(0, 0, 0, 0.05), 0 79px 79px rgba(0, 0, 0, 0.09),
    0 20px 44px rgba(0, 0, 0, 0.1);

  @media $mobile {
    padding: 32px 48px 48px;

    font-size: 16px;
    line-height: 22px;
  }
}

.block.isGhost {
  background-color: transparent;
  border-width: 0;
  box-shadow: none;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;
  margin: 0 auto;

  background-color: rgba(255, 255, 255, 0.08);

  border-radius: 40px;
}

.avatar svg {
  display: block;

  width: 46px;
}

.button {
  display: flex;
  flex-flow: column nowrap;

  gap: 24px;

  padding: 0 48px;
}

.promoMain {
  margin-bottom: 40px;
}

.promoIcon {
  width: 95px;
  margin: 0 auto 24px;
}

.promoIcon svg {
  display: block;
}

.promoTitle {
  color: #eeff85;
  font-weight: 900;
  font-size: 56px;

  font-family: $secondaryFont;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;

  font-variation-settings: 'slnt' 72;
}

.promoLink a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 40px;
  padding: 8px 16px;

  color: #0c0000;
  font-weight: 400;

  font-size: 14px;
  line-height: 20px;

  text-align: center;
  text-transform: uppercase;

  background: #eeff85;
  border-radius: 40px;
}

.promoLink a:hover {
  background-color: #e0f07d;
}

.promo p {
  color: #eeff85;
  font-weight: 400;
  font-size: 16px;

  font-family: $primaryFont;
  line-height: 20px;
  text-align: center;
}

.details {
  flex-flow: column nowrap;
  align-items: center;

  font-family: $primaryFont;

  text-align: center;
}

.subtitle {
  font-weight: 400;
  font-size: 16px;

  font-family: $primaryFont;
  line-height: 20px;
  text-align: center;

  opacity: 0.72;
}

.isDefault {
  color: rgb(238, 255, 133);
}

.isDanger {
  color: #ff3b0e;
}

.contacts {
  font-size: 16px;
  line-height: 20px;
}

.email {
  display: block;

  color: #eeff85;

  text-align: center;
  text-transform: uppercase;
}

.email a {
  color: inherit;
}

.status {
  margin: 0 0 32px;
}

.subscriptionIcon {
  display: flex;

  width: 80px;
  height: 80px;
  margin: 0 auto 16px;
}

.subscriptionTitle {
  color: #eeff85;
  font-weight: 900;
  font-size: 48px;

  font-family: $secondaryFont;
  font-style: normal;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;

  font-variation-settings: 'wdth' 72;

  @media $mobile {
    font-size: 56px;
  }
}

.control {
  display: flex;
  flex-flow: column nowrap;

  gap: 16px;
}

.description {
  max-width: 480px;
  margin: 0 auto;

  color: rgba(238, 255, 133, 0.72);

  font-size: 16px;
  line-height: 20px;
}

.profile {
  display: flex;
  flex-flow: column nowrap;

  gap: 24px;
}

.user {
  display: flex;
  flex-flow: column nowrap;

  gap: 6px;
}

.userLabel {
  color: rgba(238, 255, 133, 0.72);
  font-weight: 400;

  font-size: 14px;
  line-height: 16px;
}

.userEmail {
  display: flex;
  align-items: center;

  height: 40px;
  padding: 8px;

  color: rgba(238, 255, 133, 1);

  background: rgba(238, 255, 133, 0.02);
  border: 1px solid rgba(238, 255, 133, 0.04);
  border-radius: 4px;
}

.external {
  display: inline-block;

  width: 20px;
  height: 20px;
  margin-top: -4px;

  vertical-align: middle;
}
