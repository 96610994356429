@import '../../styles/vars.css';

.root {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  /* line-height: 20px; */

  font-family: $primaryFont;
  white-space: nowrap;
  text-transform: uppercase;

  cursor: pointer;

  user-select: none;
}

.dark {
  color: #FF3B0E;
}

.dark .control {
  background-color: transparent;
}

.input {
  position: absolute;
  top: 0;
  left: -50px;

  opacity: 0;
}

.root svg path {
  transition: transform 0.25s ease-in-out;
}

.input:checked ~ svg path {
  transform: translateX(16px);
}

.input:checked ~ svg rect {
  fill-opacity: 0.36;
}

.input:not(:checked) ~ svg path {
  transform: translateX(0);
}

.control {
  position: relative;

  display: block;

  width: 37px;
  height: 21px;

  background-color: #e6e6e6;

  border-radius: 15px;
}

.control::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  width: 18px;

  background: rgba(19, 191, 17, 0);

  border-radius: 15px;

  transition: 0.25s ease-in-out;

  content: '';
}

.knob {
  position: relative;
  top: 2px;
  left: 2px;
  z-index: $zIndex-2;

  display: block;

  width: 17px;
  height: 17px;

  background: #fff;

  border-radius: 15px;

  transition: 0.25s ease-in-out;
}

.input:checked ~ .control::before {
  width: 100%;

  background-color: rgba(255, 59, 14, 0.36);
}

.input:checked ~ .control::after {
  box-shadow: inset 0 0 0 1px transparent;
}

.input:checked ~ .control .knob {
  left: 18px;
}

.children.left {
  margin-right: 6px;
}

.children.right {
  margin-left: 6px;
  padding-top: 2px;
}

/* panel */
.isInPanel .children {
  color: #999;

  font-size: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

/* menu */
.isInMenu {
  font-weight: normal;
}

.isInMenu .children {
  margin-right: 8px;
}

.isInMenu .control {
  width: 36px;
  height: 20px;
}

.isInMenu .knob {
  top: 2px;

  width: 16px;
  height: 16px;

  background-color: #FF3B0E;
}

/* stylelint-disable selector-max-compound-selectors */
.isInMenu input:checked ~ .control .knob {
  background-color: #FF3B0E;
}

/* live */
.isInLive .children {
  color: #7f7f7f;

  font-size: 13px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.isInMenu .control {
  background-color: #FF3B0E14;
  /* box-shadow: inset 0 0 0 1px #323232; */
  box-shadow: inset 0 0 0 1px #FF3B0EB8, inset 0 0 0 1px #FF3B0E3D;
}
