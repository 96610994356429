@import '../../styles/vars.css';

.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zIndex-10;

  display: flex;
  flex-direction: row;
  align-items: flex-end;

  width: 100%;
  height: 100%;
  min-height: 120px;
  padding: 10px 0 0;

  font-size: 16px;
  line-height: 22px;

  opacity: 1;

  animation: fadeOut 250ms ease both 100ms;
}

.root.visible {
  animation: fadeIn 250ms ease both;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.7);

  contain: '';
}

.container {
  position: relative;

  flex: 1;

  max-width: 420px;
  min-height: 80px;
  margin: 0 auto;

  background: #f9f8f5;

  border-top: 2px solid rgba(225, 55, 130, 0.24);
  border-right: 2px solid rgba(225, 55, 130, 0.24);
  border-left: 2px solid rgba(225, 55, 130, 0.24);
  border-radius: 16px 16px 0 0;
  box-shadow: 0 11px 24px 0 rgba(51, 0, 0, 0.04),
    0 44px 44px 0 rgba(51, 0, 0, 0.03),
    0 99px 59px 0 rgba(51, 0, 0, 0.02),
    0 176px 70px 0 rgba(51, 0, 0, 0.01),
    0 275px 77px 0 rgba(51, 0, 0, 0);

  animation: disappear 350ms ease both;
}

.dismiss {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;

  width: 24px;
  height: 24px;
  padding: 0;

  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23a)'%3E%3Crect width='24' height='24' rx='12' fill='%23F9F8F5' fill-opacity='.04'/%3E%3Crect x='.5' y='.5' width='23' height='23' rx='11.5' stroke='url(%23b)' stroke-opacity='.12'/%3E%3Cpath d='M15.451 7.08a66.681 66.681 0 0 0-3.418 3.476l-.357-.387c-.705-.762-1.393-1.507-2.016-2.314-.172-.223-.567-.706-1.19-.64l-.062.007-.059.022c-.372.137-.687.362-.812.723-.122.351-.01.681.105.905.119.23.29.44.428.6.076.086.136.151.185.204.057.063.1.11.135.155.503.643 1.07 1.253 1.62 1.844.17.183.338.364.502.543l-.228.246c-.478.513-1.041.938-1.647 1.385l-.103.076c-.565.416-1.165.858-1.696 1.388a1.099 1.099 0 0 0-.335.87c.022.295.163.556.35.743.188.187.448.328.744.35a1.1 1.1 0 0 0 .869-.335c.538-.539 1.14-1.004 1.775-1.494l.14-.108c.53-.411 1.079-.842 1.591-1.335.781.99 1.613 1.954 2.525 2.846.246.29.573.418.9.405.317-.012.602-.155.806-.345.2-.186.375-.47.375-.805 0-.347-.186-.641-.488-.834-.922-.904-1.754-1.902-2.564-2.936 1.074-1.179 2.159-2.327 3.317-3.406.334-.145.56-.408.633-.74a1.2 1.2 0 0 0-.194-.917 1.237 1.237 0 0 0-.783-.532c-.366-.07-.739.048-1.048.34Z' stroke='%23250000' stroke-linecap='round'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='b' x1='17' y1='24' x2='17' y2='0' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23F9F8F5'/%3E%3Cstop offset='1' stop-color='%23F9F8F5' stop-opacity='.4'/%3E%3C/linearGradient%3E%3Cfilter id='a' x='-180' y='-180' width='384' height='384' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeGaussianBlur in='BackgroundImageFix' stdDeviation='90'/%3E%3CfeComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1926_1924'/%3E%3CfeBlend in='SourceGraphic' in2='effect1_backgroundBlur_1926_1924' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;

  border-width: 0;
  border-radius: 0;
  box-shadow: none;

  cursor: pointer;

  appearance: none;
}

.visible .container {
  animation: appear 350ms ease both;
}

.body {

  max-height: 68vh;
  padding: 16px 16px 32px;
  overflow-y: auto;

  color: rgba(37, 0, 0, 1);
  font-size: 16px;

  font-family: $primaryFont;
  line-height: 22px;
  -webkit-overflow-scrolling: touch;

  overscroll-behavior: none;
}

.body::before,
.body::after {
  position: absolute;
  right: 0;
  left: 0;

  content: '';
}

.body::before {
  top: 0;

  height: 29px;

  background: linear-gradient(0deg, rgba(255, 255, 250, 0) 0%, #fcfcfa 100%);

  border-radius: 16px 16px 0 0;
}

.body::after {
  bottom: 0;

  height: 48px;

  background: linear-gradient(180deg, rgba(255, 255, 250, 0) 0%, #fcfcfa 100%);
}

.body h3 {
  margin: 0 0 8px;
  padding-right: 26px;

  color: #e13782;
  font-weight: 700;

  font-size: 16px;
  line-height: 140%;
}

.body p {
  margin: 0;
}

.body p + p {
  margin-top: 6px;
}

.body a {
  color: inherit;

  box-shadow: inset 0 -1px $brandColor;
}

.body a:hover {
  color: $brandColor;
}

@keyframes appear {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes disappear {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
