@import '../../styles/vars.css';

.root {
  width: 100%;
  height: 100%;

  vertical-align: middle;
}

.small {
  height: 14px;
}

.normal {
  height: 14px;
}

.medium {
  width: 24px;
  height: 24px;
}

.large {
  width: 42px;
  height: 42px;
}

.adaptive {
  width: 100%;
  height: 100%;
}

.fb {
  width: 16px;

  fill: $fbColor;
}

.vk {
  width: 20px;

  fill: $vkColor;
}

.ok {
  width: 9px;

  fill: $okColor;
}

.tw {
  width: 20px;

  fill: $twColor;
}

.tg {
  width: 18px;

  fill: $tgColor;
}

.magic {
  width: 15px;
}

.reaction {
  width: 16px;

  color: #dcad76;

  fill: #262626;
}

.card,
.podcast,
.read,
.listened {
  width: 15px;
  height: 15px;
}

.menu {
  width: 24px;
  height: 24px;
}

.cross {
  width: 14px;
  height: 14px;
}

.isInFeature,
.isInCard {
  width: 16px;
  height: 16px;
}

.isInTopicBlockItem {
  width: 16px;
  height: 16px;
}

.isInPageStatic {
  width: 64px;
  height: 64px;
}

.isInMaterialMeta {
  margin-right: 6px;
}

.isInAudioPlayer {
  width: 29px;
  height: 29px;

  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
}

.isInEpisodeBlock {
  width: 38px;
  height: 38px;

  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
}

.isInButtonPlaybackRate {
  width: 34px;
  height: 28px;

  @media $contentWidth {
    width: 28px;
    height: 22px;
  }
}

.isInButtonBackward,
.isInButtonForward {
  width: 28px;
  height: 28px;

  @media $contentWidth {
    width: 22px;
    height: 22px;
  }
}

.isInToolbar.cross {
  width: 20px;
  height: 20px;

  fill: none;
}

.play {
  width: 100%;
  height: 100%;
}

.meduzaLogo {
  width: 92px;
  height: 20px;

  @media $landscapeTablet {
    width: 100px;
    height: 45px;
  }
}

.arrow {
  width: 12px;
  height: 12px;
}

.oil {
  width: 9px;
  height: 12px;
}

.search {
  width: 24px;
  height: 24px;

  @media $landscapeTablet {
    width: 16px;
    height: 16px;
  }
}

.user {
  width: 14px;
  height: 14px;

  @media $landscapeTablet {
    width: 16px;
    height: 16px;
  }
}

.bookmark {
  width: 17px;
  height: auto;

  cursor: pointer;

  stroke-width: 2px;

  @media $landscapeTablet {
    width: 14px;

    stroke-width: 1.5px;
  }
}

.shareBookmark {
  width: 11px;
  height: auto;

  stroke-width: 1.2px;

  cursor: pointer;
}

.isInToolbar.bookmark {
  width: 14px;
}

.receipt {
  width: 10px;
  height: 13px;
}

.isInHeader.cross {
  width: 22px;
  height: 22px;

  @media $landscapeTablet {
    width: 14px;
    height: 14px;
  }
}

.isInHeader.search circle {
  @media $landscapeTablet {
    stroke-width: 1.5px;
  }
}

.isInHeader.cross path {
  stroke-width: 1.8px;

  @media $landscapeTablet {
    stroke-width: 1.3px;
  }
}
