@import '../../styles/vars.css';

.root {
  margin: 18px 0;
  padding: 12px 12px 13px;

  font-weight: normal;
  font-size: 16px;

  font-family: $secondaryFont;
  line-height: 20px;

  background: #fff8c4;

  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border-radius: 8px;

  @media $mobile {
    margin: 25px 0;
    padding: 12px 15px 13px;

    font-size: 17px;
    line-height: 24px;
  }
}

.root:last-child {
  margin-bottom: 0;
}

.root:first-child {
  margin-top: 0;
}

.root a {
  color: #000;

  box-shadow: inset 0 -1px #000;
}

.root a:hover {
  color: $brandColor;

  box-shadow: inset 0 -1px $brandColor;
}

.slide {
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;
}

/* VISIBILITY */
.mobile {
  @media $mobile {
    display: none;
  }
}

.desktop {
  display: none;

  @media $mobile {
    display: block;
  }
}
