@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  color: rgb(var(--title-color));

  -webkit-tap-highlight-color: transparent;
}

.link {
  display: flex;
  flex-flow: column;
  flex-grow: 1;

  color: inherit;

  transition: opacity 0.2s ease-in-out;
}

.link:hover {
  opacity: 0.8;
}

.contentWrap {
  z-index: 10;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  border: 1px solid rgba(var(--title-color), 0.4);
  border-radius: 24px;
}

.is1to1 .contentWrap,
.is1to2 .contentWrap {
  text-align: center;
}

.content {
  padding: 24px 24px 36px;
}

.is1to1 .content {
  @media $landscapeTablet {
    padding: 48px 40px 56px;
  }
}

.title {
  margin-bottom: 18px;

  @media $landscapeTablet {
    margin-bottom: 20px;
  }
}

.meta {
  line-height: 14px;
}

.isTop .contentWrap {
  justify-content: flex-start;
}

.isCenter .contentWrap {
  justify-content: center;
}

.isBottom .contentWrap {
  justify-content: flex-end;
}
