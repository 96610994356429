@import '../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  width: 100%;
  margin: 16px 0;

  text-decoration: none;

  background-color: #fff;

  border-radius: 8px;
  outline-width: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);

  transition: opacity 0.2s;
  @media $portraitTablet {
    margin: 25px 0;
  }
}

.root:last-child {
  margin-bottom: 0;
}

.root:first-child {
  margin-top: 0;
}

.root:hover {
  @media $landscapeTablet {
    z-index: 10;

    opacity: 0.95;
  }
}

.container {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  border-radius: inherit;
}

.imageWrap {
  position: relative;

  width: 100%;

  border-radius: 8px 8px 0 0;

  @media $landscapeTablet {
    min-height: 0;
  }
}

.container {
  background-color: var(--bgColor);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.image {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity 350ms ease;
}

.picture {
  height: 160px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1),
    0 15px 15px 0 rgba(0, 0, 0, 0.09), 0 34px 20px 0 rgba(0, 0, 0, 0.05),
    0 60px 24px 0 rgba(0, 0, 0, 0.01), 0 94px 26px 0 rgba(0, 0, 0, 0);

  @media $landscapeTablet {
    height: 200px;
  }
}

.picture img {
  display: block;

  width: auto;
  height: 100%;

  object-fit: contain;
}

.tag {
  display: flex;

  margin-bottom: 6px;
}

.contentWrap {
  z-index: 1;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;
  padding: 0 15px;

  @media $landscapeTablet {
    padding: 0 15px;
  }
}

.contentWrap {
  @media $landscapeTablet {
    justify-content: flex-end;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: $gapSmall 0;

  @media $landscapeTablet {
    justify-content: flex-end;

    padding: 15px 0;
  }
}

.title {
  max-width: 770px;
  margin-bottom: 12px;

  @media $mobile {
    width: 100%;
    min-width: 0;
    margin-bottom: 15px;
  }
}

.footer {
  margin-top: auto;

  color: #757575;

  line-height: 14px;

  fill: currentColor;
}

.imageWrap {
  padding-top: 50px;
  padding-bottom: 50px;

  @media $landscapeTablet {
    position: static;

    flex-shrink: 0;

    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.content {
  flex-shrink: 0;

  padding-top: 0;

  @media $landscapeTablet {
    flex-grow: 0;
    justify-content: flex-end;
  }
}

.center {
  width: 100%;
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;
}

.isLight .content {
  color: #fff;
}

.isDark .content {
  --tagColor: rgba(0, 0, 0, 0.6);
  --metaText: rgba(0, 0, 0, 0.6);
  --metaFill: currentColor;
}

.isLight .content {
  --tagColor: rgba(254, 255, 255, 0.5);
  --metaText: rgba(254, 255, 255, 0.5);
  --metaFill: currentColor;
}
