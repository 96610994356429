@import '../../../../styles/vars.css';

.root {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  color: var(--title-color);

  -webkit-tap-highlight-color: transparent;
}

.link {
  color: inherit;

  transition: opacity 0.2s ease-in-out;
}

.link:hover {
  opacity: 0.9;
}

.imageWrap {
  position: relative;

  border-radius: 8px 8px 0 0;
}

.imageWrap::before {
  display: table;

  height: 600px;

  border-radius: inherit;

  content: '';
}

.is1to1 .imageWrap,
.is1to2 .imageWrap {
  height: 720px;
}

.is1to3 .imageWrap {
  height: 480px;
}

.is1to4 .imageWrap {
  height: 360px;
}

.is1to1 .imageWrap,
.is1to2 .imageWrap,
.is1to3 .imageWrap {
  @media $landscapeTablet {
    position: static;

    border-radius: 8px;
  }
}

.picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: inherit;
}

.picture img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center top;
}

.cover {
  display: flex;
  flex-flow: row wrap;

  width: 100%;

  @media $landscapeTablet {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.tag {
  display: flex;

  margin-bottom: 6px;

  line-height: 12px;

  @media $landscapeTablet {
    line-height: 14px;
  }
}

.contentWrap {
  z-index: 10;

  background: rgba(255, 255, 255, 0.1);

  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 24px;

  backdrop-filter: blur(90px);

  @media $landscapeTablet {
    position: absolute;
  }
}

.is1to1 .contentWrap {
  max-width: 710px;

  text-align: center;
}

.is1to2 .contentWrap {
  max-width: 342px;
}

.is1to3 .contentWrap {
  max-width: 292px;
}

.is1to4 .contentWrap {
  width: 100%;
  margin-top: -8px;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 24px;

  @media $landscapeTablet {
    flex-grow: 0;
    justify-content: flex-end;

    padding: 24px 24px 28px;
  }
}

.title {
  flex-grow: 1;

  margin-bottom: 16px;

  @media $landscapeTablet {
    flex-grow: 0;

    margin-bottom: 20px;
  }
}

.meta {
  line-height: 14px;
}

.is1to4 .contentWrap {
  @media $landscapeTablet {
    position: static;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    background: rgba(255, 255, 255, 0.05);
  }
}

.isLeftTop .contentWrap {
  @media $landscapeTablet {
    top: 24px;
    left: 24px;
  }
}

.isCenterTop .contentWrap {
  @media $landscapeTablet {
    top: 24px;
    left: 50%;

    transform: translateX(-50%);
  }
}

.isRightTop .contentWrap {
  @media $landscapeTablet {
    top: 24px;
    right: 24px;
  }
}

.isLeftCenter .contentWrap {
  @media $landscapeTablet {
    top: 50%;
    left: 24px;

    transform: translateY(-50%);
  }
}

.isCenterCenter .contentWrap {
  @media $landscapeTablet {
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
}

.isRightCenter .contentWrap {
  @media $landscapeTablet {
    top: 50%;
    right: 24px;

    transform: translateY(-50%);
  }
}

.isLeftBottom .contentWrap {
  @media $landscapeTablet {
    bottom: 24px;
    left: 24px;
  }
}

.isCenterBottom .contentWrap {
  @media $landscapeTablet {
    bottom: 24px;
    left: 50%;

    transform: translateX(-50%);
  }
}

.isRightBottom .contentWrap {
  @media $landscapeTablet {
    right: 24px;
    bottom: 24px;
  }
}

.withoutSticker .imageWrap::before {
  @media $landscapeTabletMax {
    width: 100%;
    height: auto;

    aspect-ratio: 1;
  }
}

.withoutSticker .contentWrap {
  @media $landscapeTabletMax {
    margin-top: -8px;
  }
}

.isSticker .contentWrap {
  @media $landscapeTabletMax {
    position: absolute;
    right: 24px;
    left: 24px;
  }
}

.isTop.isSticker .contentWrap {
  @media $landscapeTabletMax {
    top: 24px;
  }
}

.isCenter.isSticker .contentWrap {
  @media $landscapeTabletMax {
    top: 50%;

    transform: translateY(-50%);
  }
}

.isBottom.isSticker .contentWrap {
  @media $landscapeTabletMax {
    bottom: 24px;
  }
}
